import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import { createSelector } from 'reselect';
import { getDevices } from 'slices/devices/thunk';
import Select from 'react-select';
import { createConnection, getSupervisorsByZone, getUnconfiguredActiveConnections, updateConnection } from 'slices/thunks';
import { deleteParameterBackup } from 'slices/parametersBackup/thunk';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import rolesIds from 'constants/roles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import { result } from 'lodash';
import { SocketContext } from 'context/SocketProvider';

interface SelectOption {
    value: string;
    label: string;
}

interface ModalAddDeviceProps {
    isOpen: boolean;
    toggle: () => void;
    zoneId?: number;
    editMode?: boolean;
    selectedDevice?: any;
}

const getValidationSchema = (userRole: number) => {
    return Yup.object().shape({
        selectedDeviceType: Yup.object<SelectOption>().nullable().required('Tipo de Dispositivo es obligatorio'),
        selectedDeviceBrand: Yup.object<SelectOption>().nullable().required('Marca es obligatorio'),
        selectedDeviceRange: Yup.object<SelectOption>().nullable().required('Gama es obligatorio'),
        selectedDeviceModel: Yup.object<SelectOption>().nullable().required('Modelo es obligatorio'),
        selectedDeviceName: Yup.object<SelectOption>().nullable().required('Dispositivo es obligatorio'),
        selectedDeviceIp: Yup.object<SelectOption>().nullable().required('Dirección IP es obligatorio'),
        customDeviceName: Yup.string().required('Nombre Personalizado es obligatorio'),
        supervisor: Yup.object<SelectOption>().nullable(),
        isActive: Yup.boolean().required('Estado activo es obligatorio'),
        serialNumber: Yup.string().notRequired(),
        bId: Yup.string().when('selectedDeviceRange', {
            is: (range: SelectOption | null) => !!range && range.label === 'Vacon',
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.notRequired(),
        }),
        pn: Yup.string().notRequired(),
    });
};

const selectStyles = {
    control: (base: any, state: any) => ({
        ...base,
        borderRadius: '20px',
        borderColor: state.isFocused ? '#80bdff' : base.borderColor,
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#80bdff' : '#ced4da'
        }
    }),
    menu: (base: any) => ({
        ...base,
        borderRadius: '10px'
    }),
};

const SelectField = ({ name, label, options, placeholder, value, onChange, errors, touched }: any) => (
    <FormGroup>
        <Label for={name}>{label} *</Label>
        <Field name={name}>
            {({ field }: FieldProps) => (
                <Select
                    {...field}
                    id={name}
                    value={value}
                    onChange={onChange}
                    options={options}
                    placeholder={placeholder}
                    styles={selectStyles}
                    classNamePrefix="select"
                    className={errors && touched ? 'is-invalid' : ''}
                />
            )}
        </Field>
        <ErrorMessage name={name} component="div" className="text-danger" />
    </FormGroup>
);

const ModalAddDevice: React.FC<ModalAddDeviceProps> = ({ isOpen, toggle, zoneId, editMode, selectedDevice }) => {
    const dispatch: any = useDispatch();
    const selectUsersState = (state: any) => state.Users;
    const selectDeviceState = (state: any) => state.Devices;
    const selectConnectionState = (state: any) => state.Connections;
    const selectLoginState = (state: any) => state.Login;

    const socket = useContext(SocketContext);

    const userDataProperties = createSelector(
        selectUsersState,
        (state: any) => state
    );

    const loginDataProperties = createSelector(
        selectLoginState,
        (state: any) => state
    );

    const deviceDataProperties = createSelector(
        selectDeviceState,
        (state: any) => state
    );

    const connectionDataProperties = createSelector(
        selectConnectionState,
        (state: any) => state
    );

    const { supervisors } = useSelector(userDataProperties);
    const { devices } = useSelector(deviceDataProperties);
    const { unconfiguredActiveConnections } = useSelector(connectionDataProperties);
    const { user } = useSelector(loginDataProperties);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState<any>(null);

    useEffect(() => {
        if (zoneId) {
            dispatch(getUnconfiguredActiveConnections(zoneId));
        }
    }, [zoneId, dispatch, isOpen]);

    useEffect(() => {
        dispatch(getDevices());
        if (isOpen && (user.role_id === rolesIds.aplicationAdministrator || user.role_id === rolesIds.companyAdministrator)) {
            dispatch(getSupervisorsByZone(zoneId));
        }
    }, [dispatch, user, isOpen]);

    const getFilteredOptions = (
        key: string,
        selectedDeviceType: SelectOption | null,
        selectedDeviceBrand: SelectOption | null,
        selectedDeviceRange: SelectOption | null,
        selectedDeviceModel: SelectOption | null
    ) => {
        const filteredOptions = devices
            .filter((device: any) => {
                if (selectedDeviceType && key === 'brand') {
                    return device.type_device === selectedDeviceType.value;
                }
                if (selectedDeviceType && selectedDeviceBrand && key === 'range') {
                    return device.type_device === selectedDeviceType.value && device.brand === selectedDeviceBrand.value;
                }
                if (selectedDeviceType && selectedDeviceBrand && selectedDeviceRange && key === 'model') {
                    return (
                        device.type_device === selectedDeviceType.value &&
                        device.brand === selectedDeviceBrand.value &&
                        device.range === selectedDeviceRange.value
                    );
                }
                if (selectedDeviceType && selectedDeviceBrand && selectedDeviceRange && selectedDeviceModel && key === 'name') {
                    return (
                        device.type_device === selectedDeviceType.value &&
                        device.brand === selectedDeviceBrand.value &&
                        device.range === selectedDeviceRange.value &&
                        device.model === selectedDeviceModel.value
                    );
                }
                return true;
            })
            .map((device: any) => device[key])
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
        return filteredOptions.map((value: any) => ({ value, label: value }));
    };

    const getUnconfiguredIpOptions = () => {
        return unconfiguredActiveConnections.map((connection: any) => ({
            value: connection.ip,
            label: connection.ip
        }));
    };

    const initialValues = !editMode ? {
        selectedDeviceType: null,
        selectedDeviceBrand: null,
        selectedDeviceRange: null,
        selectedDeviceModel: null,
        selectedDeviceName: null,
        selectedDeviceIp: null,
        customDeviceName: '',
        serialNumber: '',
        bId: '',
        pn: '',
        supervisor: null,
        isActive: true,
    } : {
        selectedDeviceType: { value: devices.find((d: any) => d.id == selectedDevice.device_id)?.type_device || '', label: devices.find((d: any) => d.id == selectedDevice.device_id)?.type_device || '' },
        selectedDeviceBrand: { value: devices.find((d: any) => d.id == selectedDevice.device_id)?.brand || '', label: devices.find((d: any) => d.id == selectedDevice.device_id)?.brand || '' },
        selectedDeviceRange: { value: devices.find((d: any) => d.id == selectedDevice.device_id)?.range || '', label: devices.find((d: any) => d.id == selectedDevice.device_id)?.range || '' },
        selectedDeviceModel: { value: devices.find((d: any) => d.id == selectedDevice.device_id)?.model || '', label: devices.find((d: any) => d.id == selectedDevice.device_id)?.model || '' },
        selectedDeviceName: { value: devices.find((d: any) => d.id === selectedDevice.device_id)?.name || '', label: devices.find((d: any) => d.id === selectedDevice.device_id)?.name || '' },
        selectedDeviceIp: { value: selectedDevice.ip || '', label: selectedDevice.ip || '' },
        customDeviceName: selectedDevice.name || '',
        serialNumber: selectedDevice.sn || '',
        bId: selectedDevice.b_id || '',
        pn: selectedDevice.pn || '',
        supervisor: { value: selectedDevice.supervisor_id || '', label: supervisors && supervisors.find((s: any) => s.id === selectedDevice.supervisor_id)?.name || '' },
        isActive: selectedDevice.is_active,
    };

    const handleConfirm = async () => {
        try {
            if (formValues) {
                await dispatch(updateConnection(formValues)).unwrap();
                toast.success('Dispositivo actualizado con éxito!');
                if (formValues.id) {
                    await dispatch(deleteParameterBackup(formValues.id));
                }
                toggle();
            }
        } catch (error: any) {
            toast.error('Error al actualizar el dispositivo: ' + error.message);
        } finally {
            setShowConfirmModal(false);
            setFormValues(null);
        }
    };

    const handleCancel = () => {
        setShowConfirmModal(false);
        setFormValues(null);
    };

    const handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        const device = devices.find((d: any) => d.name === values.selectedDeviceName?.value);
        const connection = unconfiguredActiveConnections.find((connection: any) => connection.ip === values.selectedDeviceIp?.value);
        const formattedValues = {
            id: editMode ? selectedDevice.id : connection.id,
            ip: values.selectedDeviceIp?.value || null,
            name: values.customDeviceName || null,
            configured_connection: false,
            device_id: device?.id || null,
            zone_id: zoneId,
            sn: values.serialNumber || null,
            b_id: values.bId || null,
            pn: values.pn || null,
            supervisor_id: values.supervisor?.value || null,
            is_active: values.isActive,
            installation_id: editMode ? selectedDevice.installation_id : connection.installation_id,
            img: values.selectedDeviceRange.value
        };

        if (editMode && selectedDevice.device_id !== device?.id) {
            setFormValues(formattedValues);
            setShowConfirmModal(true);
        } else {
            try {
                await dispatch(updateConnection(formattedValues)).unwrap();
                toast.success('Dispositivo actualizado con éxito!');

                const storedData = sessionStorage.getItem('authUser') || localStorage.getItem('authUser');
                let token = null;

                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    token = parsedData.token; // Asegúrate de que 'token' es la clave correcta
                }

                console.log('Token extraído:', token);

                if (socket && token) {
                    // Asumiendo que 'new_connection_id' debería ser el ID de la conexión que se acaba de crear o actualizar
                    const new_connection_id = formattedValues.id; // Esto asume que el ID del dispositivo es también el ID de la conexión

                    socket.emit('join_new_connection', {
                        token: token,
                        new_connection_id: new_connection_id
                    });
                }
                toggle();
            } catch (error: any) {
                toast.error('Error al actualizar el dispositivo: ' + error.message);
            } finally {
                setSubmitting(false);
            }
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>{editMode ? 'EDITAR DISPOSITIVO' : 'AÑADIR DISPOSITIVO'}</ModalHeader>
                <ModalBody>
                    {unconfiguredActiveConnections && unconfiguredActiveConnections.length === 0 && !editMode ? (
                        <div className="text-center text-danger">
                            No hay conexiones disponibles.
                        </div>
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={getValidationSchema(user.role_id)}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue, setTouched, errors, touched, submitCount }) => (
                                <Form>
                                    <Row>
                                        <Col md={12}>
                                            <SelectField
                                                name="selectedDeviceType"
                                                label="Tipo de Dispositivo"
                                                options={devices.map((device: any) => ({ value: device.type_device, label: device.type_device })).filter((value: any, index: number, self: any) => self.findIndex((v: any) => v.value === value.value) === index)}
                                                placeholder="Seleccione un tipo de dispositivo..."
                                                value={values.selectedDeviceType}
                                                onChange={(selectedOption: SelectOption) => {
                                                    setFieldValue('selectedDeviceType', selectedOption);
                                                    setFieldValue('selectedDeviceBrand', null);
                                                    setFieldValue('selectedDeviceRange', null);
                                                    setFieldValue('selectedDeviceModel', null);
                                                    setFieldValue('selectedDeviceName', null);
                                                    setFieldValue('selectedDeviceIp', null);
                                                    setTouched({
                                                        selectedDeviceBrand: false,
                                                        selectedDeviceRange: false,
                                                        selectedDeviceModel: false,
                                                        selectedDeviceName: false,
                                                        selectedDeviceIp: false,
                                                    });
                                                }}
                                                errors={errors.selectedDeviceType}
                                                touched={touched.selectedDeviceType}
                                            />
                                        </Col>
                                        {values.selectedDeviceType && (
                                            <Col md={12}>
                                                <SelectField
                                                    name="selectedDeviceBrand"
                                                    label="Marca"
                                                    options={getFilteredOptions('brand', values.selectedDeviceType, values.selectedDeviceBrand, values.selectedDeviceRange, values.selectedDeviceModel)}
                                                    placeholder="Seleccione una marca..."
                                                    value={values.selectedDeviceBrand}
                                                    onChange={(selectedOption: SelectOption) => {
                                                        setFieldValue('selectedDeviceBrand', selectedOption);
                                                        setFieldValue('selectedDeviceRange', null);
                                                        setFieldValue('selectedDeviceModel', null);
                                                        setFieldValue('selectedDeviceName', null);
                                                        setFieldValue('selectedDeviceIp', null);
                                                        setTouched({
                                                            selectedDeviceRange: false,
                                                            selectedDeviceModel: false,
                                                            selectedDeviceName: false,
                                                            selectedDeviceIp: false,
                                                        });
                                                    }}
                                                    errors={errors.selectedDeviceBrand}
                                                    touched={touched.selectedDeviceBrand}
                                                />
                                            </Col>
                                        )}
                                        {values.selectedDeviceBrand && (
                                            <Col md={12}>
                                                <SelectField
                                                    name="selectedDeviceRange"
                                                    label="Gama"
                                                    options={getFilteredOptions('range', values.selectedDeviceType, values.selectedDeviceBrand, values.selectedDeviceRange, values.selectedDeviceModel)}
                                                    placeholder="Seleccione una gama..."
                                                    value={values.selectedDeviceRange}
                                                    onChange={(selectedOption: SelectOption) => {
                                                        setFieldValue('selectedDeviceRange', selectedOption);
                                                        setFieldValue('selectedDeviceModel', null);
                                                        setFieldValue('selectedDeviceName', null);
                                                        setFieldValue('selectedDeviceIp', null);
                                                        setTouched({
                                                            selectedDeviceModel: false,
                                                            selectedDeviceName: false,
                                                            selectedDeviceIp: false,
                                                        });
                                                    }}
                                                    errors={errors.selectedDeviceRange}
                                                    touched={touched.selectedDeviceRange}
                                                />
                                            </Col>
                                        )}
                                        {values.selectedDeviceRange && (
                                            <Col md={12}>
                                                <SelectField
                                                    name="selectedDeviceModel"
                                                    label="Modelo"
                                                    options={getFilteredOptions('model', values.selectedDeviceType, values.selectedDeviceBrand, values.selectedDeviceRange, values.selectedDeviceModel)}
                                                    placeholder="Seleccione un modelo..."
                                                    value={values.selectedDeviceModel}
                                                    onChange={(selectedOption: SelectOption) => {
                                                        setFieldValue('selectedDeviceModel', selectedOption);
                                                        setFieldValue('selectedDeviceName', null);
                                                        setFieldValue('selectedDeviceIp', null);
                                                        setTouched({
                                                            selectedDeviceName: false,
                                                            selectedDeviceIp: false,
                                                        });
                                                    }}
                                                    errors={errors.selectedDeviceModel}
                                                    touched={touched.selectedDeviceModel}
                                                />
                                            </Col>
                                        )}
                                        {values.selectedDeviceModel && (
                                            <Col md={12}>
                                                <SelectField
                                                    name="selectedDeviceName"
                                                    label="Dispositivo"
                                                    options={getFilteredOptions('name', values.selectedDeviceType, values.selectedDeviceBrand, values.selectedDeviceRange, values.selectedDeviceModel)}
                                                    placeholder="Seleccione un dispositivo..."
                                                    value={values.selectedDeviceName}
                                                    onChange={(selectedOption: SelectOption) => {
                                                        setFieldValue('selectedDeviceName', selectedOption);
                                                        if (!editMode) {
                                                            setFieldValue('selectedDeviceIp', null);
                                                        }
                                                        setTouched({
                                                            selectedDeviceIp: false,
                                                        });
                                                    }}
                                                    errors={errors.selectedDeviceName}
                                                    touched={touched.selectedDeviceName}
                                                />
                                            </Col>
                                        )}
                                        {values.selectedDeviceName && (
                                            <Col md={12}>
                                                <SelectField
                                                    name="selectedDeviceIp"
                                                    label="Dirección IP (asegúrece que el dispositivo tenga la misma)"
                                                    options={getUnconfiguredIpOptions()}
                                                    placeholder="Seleccione una dirección IP..."
                                                    value={values.selectedDeviceIp}
                                                    onChange={(selectedOption: SelectOption) => {
                                                        setFieldValue('selectedDeviceIp', selectedOption);
                                                    }}
                                                    errors={errors.selectedDeviceIp}
                                                    touched={touched.selectedDeviceIp}
                                                />
                                            </Col>
                                        )}
                                        {values.selectedDeviceIp && (
                                            <>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label for="customDeviceName">Nombre Personalizado *</Label>
                                                        <Field name="customDeviceName">
                                                            {({ field }: FieldProps) => (
                                                                <Input
                                                                    {...field}
                                                                    maxLength={18}
                                                                    type="text"
                                                                    id="customDeviceName"
                                                                    placeholder="Ingrese un nombre personalizado..."
                                                                    className={`form-control rounded-pill ${errors.customDeviceName && touched.customDeviceName ? 'is-invalid' : ''}`}
                                                                />
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name="customDeviceName" component="div" className="text-danger" />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Row className='align-items-center'>
                                                            <Col xs="auto">
                                                                <Label for="isActive">Estado Activo</Label>
                                                            </Col>
                                                            <Col>
                                                                <div className="form-check form-switch form-switch-lg">
                                                                    <Field name="isActive">
                                                                        {({ field }: FieldProps) => (
                                                                            <input
                                                                                {...field}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="activeSwitch"
                                                                                checked={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <ErrorMessage name="isActive" component="div" className="text-danger" />
                                                    </FormGroup>
                                                </Col>

                                                {values.selectedDeviceRange && (values.selectedDeviceRange as SelectOption).label === 'Vacon' && (
                                                    <>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label for="bId">BID</Label>
                                                                <Field name="bId">
                                                                    {({ field }: FieldProps) => (
                                                                        <Input
                                                                            {...field}
                                                                            type="text"
                                                                            id="bId"
                                                                            placeholder="Ingrese el BID..."
                                                                            className={`form-control rounded-pill ${errors.bId && touched.bId ? 'is-invalid' : ''}`}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage name="bId" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label for="serialNumber">Número de Serie</Label>
                                                                <Field name="serialNumber">
                                                                    {({ field }: FieldProps) => (
                                                                        <Input
                                                                            {...field}
                                                                            type="text"
                                                                            id="serialNumber"
                                                                            placeholder="Ingrese el número de serie..."
                                                                            className={`form-control rounded-pill ${errors.serialNumber && touched.serialNumber ? 'is-invalid' : ''}`}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage name="serialNumber" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label for="pn">P/N</Label>
                                                                <Field name="pn">
                                                                    {({ field }: FieldProps) => (
                                                                        <Input
                                                                            {...field}
                                                                            type="text"
                                                                            id="pn"
                                                                            placeholder="Ingrese el P/N..."
                                                                            className={`form-control rounded-pill ${errors.pn && touched.pn ? 'is-invalid' : ''}`}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage name="pn" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                    </>
                                                )}
                                                {values.selectedDeviceIp && (
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label for="supervisor">Supervisor</Label>
                                                            <Field name="supervisor">
                                                                {({ field }: FieldProps) => (
                                                                    <Select
                                                                        {...field}
                                                                        id="supervisor"
                                                                        value={values.supervisor}
                                                                        onChange={(selectedOption: SelectOption) => setFieldValue('supervisor', selectedOption)}
                                                                        options={
                                                                            supervisors && supervisors.length > 0
                                                                                ? [
                                                                                    { value: '', label: 'Ninguno' },
                                                                                    ...supervisors.map((supervisor: any) => ({ value: supervisor.id, label: supervisor.name }))
                                                                                ]
                                                                                : [{ value: '', label: 'Ninguno' }]
                                                                        }
                                                                        placeholder="Seleccione un supervisor (opcional)..."
                                                                        styles={selectStyles}
                                                                        classNamePrefix="select"
                                                                        className={errors && touched ? 'is-invalid' : ''}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name="supervisor" component="div" className="text-danger" />
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            </>
                                        )}
                                    </Row>
                                    <div className="d-flex justify-content-center">
                                        <Button color="secondary" onClick={toggle} className="rounded-pill me-2">Cancelar</Button>
                                        <Button color="primary" type="submit" className="rounded-pill">Guardar Cambios</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </ModalBody>
            </Modal>
            <ModalConfirm
                isOpen={showConfirmModal}
                toggle={handleCancel}
                title={"Cambiar dispositivo"}
                message={`¿Estás seguro de que quieres cambiar de dispositivo? El backup se borrará.`}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                idSelected={formValues?.id || null}
                iconName='mdi mdi-alert-outline display-5 text-danger'
            />
        </>
    );
};

export default ModalAddDevice;
