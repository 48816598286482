import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
    Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Input,
    Modal, ModalBody, ModalHeader, Row, Spinner, UncontrolledDropdown, Button, Form, FormGroup, Label
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//redux
import { useSelector, useDispatch } from 'react-redux';

import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import SimpleBar from 'simplebar-react';
import BreadCrumb from 'Components/Common/BreadCrumb';
import TableContainer from 'Components/Common/TableContainerReactTable';
import GeneralData from 'pages/Dashboard/GeneralData';
import MenuParameters from './ParametersList';
import { SocketContext } from 'context/SocketProvider';
import { updateParameterBackup, deleteParameterBackup } from '../../slices/parametersBackup/thunk';
import { getParameterValuesByConnection } from 'slices/parameterValues/thunk';
import { getFavoritesByConnectionId, createFavorite, deleteFavorite, isAnalogicUsed } from 'slices/favorites/thunk';
import { resetValues, setDeleteBackup, setFilteredParametersBackup, setValueChanged, updateFilteredParameterValue, updateParameterBackupValue, updateParameterBackupValueParametrization } from 'slices/parametersBackup/reducer';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import { text } from 'stream/consumers';
import { set } from 'lodash';
import { es, el, id } from 'date-fns/locale';

const Parameters = () => {
    document.title = "Parámetros | Ecentroserver";
    const { id } = useParams();
    const dispatch: any = useDispatch();
    const socket = useContext(SocketContext);
    const connectionId = Number(id);
    const [helpModal, setHelpModal] = useState(false);
    const [helpContent, setHelpContent] = useState("");
    const [customHelpContent, setCustomHelpContent] = useState("");
    const [iconUrl, setIconUrl] = useState("");
    const [favoriteModal, setFavoriteModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentUnit, setCurrentUnit] = useState("");
    const [currentType, setCurrentType] = useState("");
    const [selectedParameterId, setSelectedParameterId] = useState<number | null>(null);
    const [favoriteToRemove, setFavoriteToRemove] = useState<number | null>(null);
    const [currentVariable, setCurrentVariable] = useState<string>("");
    const [favoriteNameToRemove, setFavoriteNameToRemove] = useState<string>("");
    const connectionID = Number(id);
    const [analogicResponse, setAnalogicResponse] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [unitOptions, setUnitOptions] = useState<{ value: string; label: string }[]>([]);
    const [matchValues, setMatchValues] = useState<any>({});

    const navigate = useNavigate();

    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const selectFavoritesState = (state: any) => state.Favorites;
    const selectInstallationsState = (state: any) => state.Installations;

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state: any) => state
    );

    const parameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state: any) => state
    );

    const FavoritesProperties = createSelector(
        selectFavoritesState,
        (state: any) => state
    );

    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state) => state
    );

    const { filteredParameterBackup, parametersBackup, currentParameterPath, valueChanged, deleteBackup } = useSelector(parametersBackupProperties);
    const { parameterValues } = useSelector(parameterValuesProperties);
    const { favorites } = useSelector(FavoritesProperties);
    const { installations } = useSelector(installationDataProperties);

    const toggleHelpModal = () => {
        setHelpModal(!helpModal);
    };

    const fetchAnalogicUsage = async (unit: any, parameterId: any, connectionIp: any, deviceRange: any) => {
        const response = await dispatch(isAnalogicUsed({
            ip: connectionIp,
            parameter_id: Number(parameterId),
            device: deviceRange
        }));


        if (response.payload) {
            const matchValues = response.payload;
            setMatchValues(matchValues);

            let minScale, maxScale, decimalNumbers, unitText;
            let units: any = [];

            const keyDescriptions: any = {
                "actual_value_1_ext_pid": "PID externo valor actual 1",
                "actual_value_1_pid": "PID valor actual 1",
                "actual_value_2_ext_pid": "PID externo valor actual 2",
                "actual_value_2_pid": "PID valor actual 2",
                "input_pressure_control": "supervisión presión de entrada"
            };

            for (const key in response.payload) {
                const item = response.payload[key];
                const factor = Math.pow(10, item.decimal_numbers);

                minScale = item.min_unit ? item.min_unit.read_value / factor : minScale;
                maxScale = item.max_unit ? item.max_unit.read_value / factor : maxScale;
                decimalNumbers = item.decimal_numbers || decimalNumbers;  // Usar OR para mantener el último valor definido si no hay decimal_numbers

                if (item.unit_text) {
                    unitText = item.unit_text;

                    let matchText = keyDescriptions[key] || key;
                    console.log("key", key);

                    units.push({ key: matchText, unit: unitText });
                }
            }
            setAnalogicResponse({ minScale, maxScale, decimalNumbers, unitText });
            favoriteFormik.setFieldValue("unit", unitText);
            favoriteFormik.setFieldValue("min_scale", minScale);
            favoriteFormik.setFieldValue("max_scale", maxScale);
            favoriteFormik.setFieldValue("decimal_count", decimalNumbers);
            setUnitOptions(units.map((unit: any) => ({ value: unit.unit, label: `${unit.unit} (${unit.key})` })));


            let usages = [];
            if (matchValues["actual_value_1_ext_pid"]?.match) usages.push("PID externo valor actual 1");
            if (matchValues["actual_value_1_pid"]?.match) usages.push("PID valor actual 1");
            if (matchValues["actual_value_2_ext_pid"]?.match) usages.push("PID externo valor actual 2");
            if (matchValues["actual_value_2_pid"]?.match) usages.push("PID valor actual 2");
            if (matchValues["input_pressure_control"]?.match) usages.push("supervisión de presión de entrada");

            if (usages.length > 0) {
                let errorMessage = "Se ha detectado que la analógica está siendo usada en ";

                errorMessage += usages.length === 2
                    ? usages.join(" y ")
                    : usages.slice(0, -1).join(", ") + (usages.length > 2 ? ", y " : "") + usages[usages.length - 1];
                errorMessage += ".";
                setErrorMessage(errorMessage);
            } else {
                setErrorMessage(null);
            }
        }
    };


    const toggleFavoriteModal = async (unit: any, id: any, variable: any, parameterId = null, connectionIp = null, deviceRange = null, type = '') => {
        setCurrentUnit(unit || "");
        setCurrentType(type || "");
        setSelectedParameterId(id);
        setCurrentVariable(variable);
        setFavoriteModal(!favoriteModal);
        setErrorMessage(null)

        await fetchAnalogicUsage(unit, parameterId, connectionIp, deviceRange);

    };

    const toggleConfirmModal = (parameterId: number | null, name: string) => {
        setFavoriteToRemove(parameterId);
        setFavoriteNameToRemove(name);
        setConfirmModal(!confirmModal);
    };


    /*   useEffect(() => {
          if (parameterValues[Number(id)]) {
              dispatch(getFavoritesByConnectionId(Number(id)));
          }
      }, [dispatch, id, parameterValues]); */

    const transformEmptyValuesToNull = (obj: any) => {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [key, value === '' ? null : value])
        );
    };

    const handleFavoriteSubmit = (values: any) => {
        const parameter = {
            ...values,
            parameter_id: selectedParameterId,
            connection_id: connectionId
        };

        const transformedParameter = transformEmptyValuesToNull(parameter);

        dispatch(createFavorite(transformedParameter)).then(() => {
            toast.success("Favorito creado correctamente.");
        });

        toggleFavoriteModal("", null, "");
    };

    const handleRemoveFavorite = async (parameterId: number | null) => {
        if (parameterId !== null) {
            const favoriteToDelete = favorites[connectionId].find((fav: any) => fav.parameter_id == parameterId);

            if (favoriteToDelete) {
                try {
                    await dispatch(deleteFavorite({ favoriteId: favoriteToDelete.id, connectionId: connectionId }));
                    toast.success("Favorito eliminado correctamente.");
                } catch (error) {
                    toast.error("Error al eliminar el favorito.");
                }
            } else {
                toast.error("Favorito no encontrado.");
            }
            setFavoriteToRemove(null);
            setFavoriteNameToRemove("");
        }
    };

    useEffect(() => {
        if (socket) {
            socket.on('changeValueResponse', (response) => {
                console.log("changeValueResponse", response);
                if (response.status === 'success') {
                    toast.dismiss('waitingToast');
                    toast.success("Se ha modificado el parámetro correctamente.");


                    const parameterChangeValues = parametersBackup[connectionId].parameters
                        .filter((parameter: any) => parameter.parameter_id === response.id.toString())
                        .map((parameter: any) => ({
                            ...parameter,
                            connection_id: connectionId
                        }));

                    parameterChangeValues.forEach((parameter: any) => {
                        parameter.value = response.value;
                    });

                    console.log("parameterChangeValues", parameterChangeValues);

                    dispatch(updateParameterBackupValueParametrization(parameterChangeValues));

                    if (deleteBackup) {
                        dispatch(deleteParameterBackup(connectionId)).then(() => {
                            navigate(`/dispositivo/${connectionId}`);
                        });
                        dispatch(setDeleteBackup(false))
                    }


                    const parameter = parametersBackup[connectionId].parameters.find((parameter: any) => parameter.parameter_id == response.id.toString());
                    const newParametersBackup = parametersBackup[connectionId].parameters.map((parameter: any) => {
                        if (parameter.parameter_id == response.id.toString()) {
                            return { ...parameter, value: response.value };
                        }
                        return parameter;
                    });

                    const parameterInfo = {
                        connection_id: Number(id),
                        parameter_id: response.id,
                        value: response.value,
                        parameterValues: parameterValues[connectionId][parameter.id],
                        parametersBackup: newParametersBackup
                    };

                    dispatch(updateParameterBackup(parameterInfo));
                } else {
                    toast.error("Hubo un error a la hora de modificar el parámetro");
                }
            });
        }

        return () => {
            if (socket) {
                socket.off('changeValueResponse');
            }
        };
    }, [socket, parametersBackup, parameterValues, deleteBackup]);

    const allColumns = useMemo(
        () => [
            {
                Header: "Indice",
                accessor: "index",
                disableFilters: true,
                filterable: false,
                width: 10,
            },
            {
                Header: "Variable",
                accessor: "variable",
                disableFilters: true,
                filterable: false,
                width: 150,
            },
            {
                Header: "Valor",
                accessor: "value",
                disableFilters: true,
                filterable: false,
                width: 150,
            },
            {
                Header: "Min",
                accessor: "min",
                disableFilters: true,
                filterable: false,
                width: 70,
            },
            {
                Header: "Max",
                accessor: "max",
                disableFilters: true,
                filterable: false,
                width: 70,
            },
            {
                Header: "Unidad",
                accessor: "unit",
                disableFilters: true,
                filterable: false,
                width: 10,
            },
            {
                Header: "Defecto",
                accessor: "default",
                disableFilters: true,
                filterable: false,
                width: 70
            },
            {
                Header: "ID",
                accessor: "parameter_id",
                disableFilters: true,
                filterable: false,
                width: 70
            },
            {
                Header: "Ayuda",
                accessor: "help",
                disableFilters: true,
                filterable: false,
                width: 30,
                Cell: ({ cell: { value }, row: { original } }: any) => (
                    <i

                        className={original.custom_help ? 'bx bxs-help-circle fs-20' : 'bx bx-help-circle fs-20'}
                        /* className="mdi mdi-help-circle fs-3 cursor-pointer" */
                        aria-hidden="true"
                        onClick={() => {
                            setHelpContent(value);
                            setCustomHelpContent(original.custom_help);
                            setIconUrl(original.icon_url);
                            toggleHelpModal();
                        }}
                    ></i>
                ),
            },
            {
                Header: "Favorito",
                accessor: "favorite",
                disableFilters: true,
                filterable: false,
                width: 30,
                Cell: ({ row: { original } }: any) => {

                    return (
                        <div>
                            <button
                                type="button"
                                className={`btn btn-ghost-primary btn-icon btn-sm favourite-btn ${original.favorite ? 'active' : ''}`}
                                onClick={() => original.favorite ? toggleConfirmModal(original.id, original.favorite.name) : toggleFavoriteModal(original.unit, original.id, original.variable, original.parameter_id, original.connection_ip, original.device_range, original.parameter_type)}
                                disabled={original.custom_help && !original.icon_url}
                                style={{ marginBottom: '5px' }}
                            >
                                <i className={`ri-star-${original.favorite ? 'fill' : 'line'} fs-13 align-bottom`} />
                            </button>
                        </div>
                    );
                },
            },
        ],
        [favorites, connectionId]
    );

    const [sidebarData, setSidebarData] = useState<any>("");
    const [filterActive, setFilterActive] = useState<any>("");

    function sidebarOpen(value: any) {
        const element = document.getElementsByTagName('body')[0];
        element.classList.add(value);
    }

    function sidebarClose(value: any) {
        const element = document.getElementsByTagName('body')[0];
        element.classList.remove(value);
    }

    useEffect(() => {
        sidebarOpen("file-detail-show");
    }, []);

    useEffect(() => {
        const newFilteredParameterBackup = filteredParameterBackup.map((value: any) => ({
            ...value,
            value: ""
        }));

        if (newFilteredParameterBackup.length > 0) {
            /*      dispatch(resetValues()); */

            const visualizationParameters = newFilteredParameterBackup.filter((value: any) => value.parameter_type === "Visualization");

            if (visualizationParameters.length > 0) {
                const allValuesEmpty = visualizationParameters.every((value: any) => value.value == "");

                if (allValuesEmpty) {
                    const registers = visualizationParameters.map((value: any) => ({
                        parameter_id: value.parameter_id,
                        min: value.min,
                        max: value.max,
                        is_active: value.is_active,
                        bit_parameter_id: value.bit_parameter_id,
                        idmap_id: value.idmap_id,
                        unit: value.unit,
                        connection_id: connectionId
                    }));
                    const ip = parametersBackup[connectionId].connection_ip;

                    setSidebarData({ [ip]: registers });
                }
            } else {
                if (socket) {
                    socket.emit("handle_stop_modbus_values");
                }
            }
        }
    }, [currentParameterPath]);



    useEffect(() => {
        if (socket) {

            socket.on('send_modbus_values_multiples_ips', (data) => {
                if (filteredParameterBackup.length > 0 && data && data.values[0].connection_id === connectionID) {

                    dispatch(updateFilteredParameterValue({ data: data.values, connectionId: connectionID }));
                }
            });

            return () => {
                if (socket) {
                    /*  socket.off('changeValueResponse'); */
                    /*                     socket.off('send_modbus_values_multiples_ips'); */
                }
            };

        }
    }, [socket, filteredParameterBackup, connectionID]);



    const favoriteValidationSchema = Yup.object({
        name: Yup.string().required('El nombre personalizado es obligatorio'),
    });

    const favoriteFormik = useFormik({
        initialValues: {
            name: '',
            min_scale: '',
            max_scale: '',
            unit: '',
            decimal_count: 0
        },
        validationSchema: favoriteValidationSchema,
        onSubmit: handleFavoriteSubmit,
    });

    useEffect(() => {
        if (favoriteModal) {
            const initialValues = {
                name: currentVariable,
                min_scale: '',
                max_scale: '',
                unit: currentUnit || '',
                decimal_count: 0
            };

            if (analogicResponse) {
                initialValues.min_scale = analogicResponse.minScale;
                initialValues.max_scale = analogicResponse.maxScale;
                initialValues.unit = analogicResponse.unitText;
                initialValues.decimal_count = analogicResponse.decimal_numbers;
            }

            favoriteFormik.resetForm({ values: initialValues });
        }
    }, [favoriteModal, currentUnit, currentVariable, analogicResponse]);

    const handleUnitChange = (event: any) => {
        const { value } = event.target;

        favoriteFormik.setFieldValue("unit", value);

        // Buscar en matchValues el objeto donde unit_text coincide con la unidad seleccionada
        let found = false;
        for (const key in matchValues) {

            if (matchValues[key].unit_text === value) {
                const { min_unit, max_unit, decimal_numbers } = matchValues[key];
                const factor = Math.pow(10, decimal_numbers);
                const minScale = min_unit ? min_unit.read_value / factor : '';
                const maxScale = max_unit ? max_unit.read_value / factor : '';
                favoriteFormik.setFieldValue("min_scale", minScale);
                favoriteFormik.setFieldValue("max_scale", maxScale);
                favoriteFormik.setFieldValue("decimal_count", decimal_numbers);
                found = true;
                break;
            }
        }

        // Si no se encuentra una coincidencia, resetear los valores
        if (!found) {
            favoriteFormik.setFieldValue("min_scale", '');
            favoriteFormik.setFieldValue("max_scale", '');
            favoriteFormik.setFieldValue("decimal_count", 0);
        }
    };

    const installation = installations?.find((inst: any) =>
        inst.zones.some((zone: any) => zone.connections.some((conn: any) => conn.id === connectionID))
    );
    const zone = installation?.zones.find((zone: any) =>
        zone.connections.some((conn: any) => conn.id === connectionID)
    );
    const connection = zone?.connections.find((conn: any) => conn.id === connectionID);

    return (
        <React.Fragment>
            <BreadCrumb
                pageTitle={installation && installation.installation_name}
                pageTitleUrl={`/dashboard/instalacion/${installation && installation.id}`}
                subtitle={zone && zone.zone_name}
                subtitleUrl={`/dashboard/zona/${zone && zone.id}`}
                subsubtitle={parametersBackup && parametersBackup[connectionID] ? parametersBackup[connectionID].connection_name : ''}
                subsubtitleUrl={`/dispositivo/${connectionID}`}
                subsubsubtitle='Parámetros'
            />
            <div className="page-content">
                <Col lg={12} className="order-xxl-0 order-first">
                    <GeneralData registers={sidebarData} />
                </Col>

                <MenuParameters
                    allColumns={allColumns}
                    parametersList={filteredParameterBackup}
                    filterActive={filterActive}
                    connectionId={Number(id)}
                />
            </div>

            <Modal isOpen={helpModal} toggle={toggleHelpModal} centered>
                <ModalHeader toggle={toggleHelpModal}>Ayuda</ModalHeader>
                <ModalBody>
                    <p>{helpContent}</p>
                    <p style={{ color: 'blue' }}>{customHelpContent}</p>
                    {iconUrl && (
                        <div className='d-flex justify-content-center'>
                            <Button color="primary" onClick={() => window.open('/pdfs/pdf_block.pdf', '_blank')}>
                                <i className='bx bxs-file-pdf fs-22'></i>
                            </Button>
                        </div>

                    )}
                </ModalBody>
            </Modal>

            <Modal isOpen={favoriteModal} toggle={() => toggleFavoriteModal("", null, "")} centered>
                <ModalHeader toggle={() => toggleFavoriteModal("", null, "")}>Añadir a Favoritos</ModalHeader>
                <ModalBody>
                    {errorMessage && <div className="mt-1 alert alert-danger">{errorMessage}</div>}
                    <Form onSubmit={favoriteFormik.handleSubmit}>
                        <FormGroup>
                            <Label for="name">Nombre Personalizado *</Label>
                            <Input
                                type="text"
                                className='rounded-pill'
                                name="name"
                                id="name"
                                value={favoriteFormik.values.name || ''}
                                onChange={favoriteFormik.handleChange}
                                onBlur={favoriteFormik.handleBlur}
                                invalid={favoriteFormik.touched.name && !!favoriteFormik.errors.name}
                            />
                            {favoriteFormik.touched.name && favoriteFormik.errors.name ? (
                                <FormFeedback>{favoriteFormik.errors.name}</FormFeedback>
                            ) : null}
                        </FormGroup>
                        {currentUnit === "%" && currentType == "Visualization" && currentVariable.startsWith("Entrada") && (
                            <>
                                <FormGroup>
                                    <Label for="min_scale">Escala Mínima</Label>
                                    <Input
                                        type="number"
                                        className='rounded-pill'
                                        name="min_scale"
                                        id="min_scale"
                                        value={favoriteFormik.values.min_scale}
                                        onChange={favoriteFormik.handleChange}
                                        disabled={!!errorMessage}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="max_scale">Escala Máxima</Label>
                                    <Input
                                        type="number"
                                        className='rounded-pill'
                                        name="max_scale"
                                        id="max_scale"
                                        value={favoriteFormik.values.max_scale}
                                        onChange={favoriteFormik.handleChange}
                                        disabled={!!errorMessage}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="decimal_count">Decimales</Label>
                                    <Input
                                        type="number"
                                        className='rounded-pill'
                                        name="decimal_count"
                                        id="decimal_count"
                                        value={favoriteFormik.values.decimal_count}
                                        onChange={favoriteFormik.handleChange}
                                        disabled={!!errorMessage}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="unit">Unidad</Label>
                                    {errorMessage ? (
                                        <Input
                                            type="select"
                                            name="unit"
                                            id="unit"
                                            className='rounded-pill'
                                            value={favoriteFormik.values.unit}
                                            onChange={handleUnitChange}
                                        >
                                            {unitOptions.map((option, index) => (
                                                <option key={index} value={option.value}>{option.label}</option>
                                            ))}
                                        </Input>
                                    ) : (
                                        <Input
                                            type="text"
                                            className='rounded-pill'
                                            name="unit"
                                            id="unit"
                                            value={favoriteFormik.values.unit || ''}
                                            onChange={favoriteFormik.handleChange}
                                        />
                                    )}
                                </FormGroup>

                            </>
                        )}

                        <div className="text-end">
                            <Button type="submit" color="primary">Guardar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <ModalConfirm
                isOpen={confirmModal}
                toggle={() => toggleConfirmModal(null, "")}
                title="Eliminar Favorito"
                message={`¿Estás seguro de que deseas eliminar el favorito "${favoriteNameToRemove}"?`}
                onConfirm={handleRemoveFavorite}
                onCancel={() => setFavoriteToRemove(null)}
                iconName='ri-information-line display-5 text-warning'
                idSelected={favoriteToRemove}
            />
        </React.Fragment>
    );
};

export default Parameters;
