import React, { useState, useContext } from 'react';
import { SocketContext } from 'context/SocketProvider'; // Asegúrate de que la ruta sea correcta
import ModalConfirm from './Modals/ModalConfirm';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


const ResetZone = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const socket = useContext(SocketContext);
    const { id } = useParams();


    const selectFavoritesState = (state: any) => state.Favorites;

    const FavoritesProperties = createSelector(
        selectFavoritesState,
        (state) => state
    );

    const { dashBoardInfo } = useSelector(FavoritesProperties);


    const toggleModal = () => setModalOpen(!modalOpen);

    const handleConfirm = () => {
        const registers = dashBoardInfo[Number(id)].ip_registers;
        let keysArray = [];

        if (Array.isArray(registers)) {
            // Si registers es un array, extraer las claves de cada objeto
            keysArray = registers.reduce((acc, register) => {
                return acc.concat(Object.keys(register));
            }, []);
        } else if (typeof registers === 'object' && registers !== null) {
            // Si registers es un objeto, extraer las claves directamente
            keysArray = Object.keys(registers);
        } else {
            console.error("registers no es ni un array ni un objeto:", registers);
        }

        if (socket) {
            toast.info('Se ha enviado la petición de reseteo de dispositivos. Por favor espere...');
            socket.emit('resetDevices', keysArray);
        }

        toggleModal();
    };

    return (
        <div className="ms-1 header-item  d-sm-flex">
            <button
                onClick={toggleModal}
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none">
                <i className='bx bx-refresh fs-24'></i>
            </button>

            <ModalConfirm
                isOpen={modalOpen}
                toggle={toggleModal}
                title="Resetear Zona"
                message="¿Estás seguro de que quieres resetear esta zona?"
                onConfirm={handleConfirm}
                onCancel={toggleModal}
                iconName='ri-refresh-line display-5 text-warning'
                idSelected={dashBoardInfo}
            />
        </div>
    );
};

export default ResetZone;
