import { createSlice } from "@reduxjs/toolkit";
import {
  createStatusZone,
  getStatusZone,
  deleteStatusZone,
  updateStatusZone,
  reorderedStatusZones
} from "./thunk";

export const initialState: any = {
  statusZone: {},  // Cambiado de array a objeto clave-valor
  loadedStatusZone: false,
};

const statusZoneSlice = createSlice({
  name: "statusZone",
  initialState,
  reducers: {
    setStatusZoneLoaded: (state, action) => {
      state.loadedStatusZone = action.payload;
    },
    updateStatusZoneValues: (state, action) => {
      const { parametersBackup, data } = action.payload;


      Object.keys(state.statusZone).forEach((installationZoneId) => {
        const zones = state.statusZone[installationZoneId];
        if (zones) {
          zones.forEach((statusZoneItem: any) => {
          
            const connectionId = statusZoneItem.connection_id;
            const parameterId = statusZoneItem.parameter_id;
            

            const parameters = parametersBackup[connectionId]?.status || [];
            const matchingParameters = parameters.find((param: any) => param.id === parameterId);

         
            if (matchingParameters) {
              const matchingData = data && data.find((dataItem: any) =>
                Number(matchingParameters.parameter_id) === Number(dataItem.parameter_id) &&
                connectionId === dataItem.connection_id &&
                (matchingParameters.bit_parameter_id == null || matchingParameters.bit_parameter_id === dataItem.bit_parameter_id) &&
                (matchingParameters.idmap_id == null || matchingParameters.idmap_id === dataItem.idmap_id)
              );


              if (matchingData) {
                const value = matchingData.value;
                statusZoneItem.value = value === "1" || value === 1 ? true : value === "0" || value === 0 ? false : statusZoneItem.value;
              }
            }
          });
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatusZone.fulfilled, (state: any, action: any) => {
      action.payload.forEach((statusZone: any) => {
        if (!state.statusZone[statusZone.installation_zone_id]) {
          state.statusZone[statusZone.installation_zone_id] = [];
        }

        const existingZoneIndex = state.statusZone[statusZone.installation_zone_id].findIndex((z: any) => z.id === statusZone.id);
        if (existingZoneIndex === -1) {
          state.statusZone[statusZone.installation_zone_id].push(statusZone);
        } else {

          state.statusZone[statusZone.installation_zone_id][existingZoneIndex] = statusZone;
        }
      });
    });


    builder.addCase(getStatusZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createStatusZone.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        const { installation_zone_id } = action.payload;
    
        if (!Array.isArray(state.statusZone[installation_zone_id])) {
          state.statusZone[installation_zone_id] = [];
        }
    
        state.statusZone[installation_zone_id].push(action.payload);
      }
    });

    builder.addCase(createStatusZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateStatusZone.fulfilled, (state: any, action: any) => {
      const { installation_zone_id, id } = action.payload;
    

      if (Array.isArray(state.statusZone[installation_zone_id])) {
        const existingZoneIndex = state.statusZone[installation_zone_id].findIndex((zone: any) => zone.id === id);
    
        if (existingZoneIndex !== -1) {
          state.statusZone[installation_zone_id][existingZoneIndex] = {
            ...state.statusZone[installation_zone_id][existingZoneIndex],
            ...action.payload,
          };
        }
      }
    });
    

    builder.addCase(updateStatusZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(reorderedStatusZones.fulfilled, (state: any, action: any) => {
      const reorderedIds = action.payload;

      const updatedStatusZones: any = {};

      Object.keys(state.statusZone).forEach((installationZoneId) => {
        const zonesArray = state.statusZone[installationZoneId];

        updatedStatusZones[installationZoneId] = reorderedIds
          .map((id: any) => zonesArray.find((zone: any) => zone.id === id))
          .filter(Boolean); 
      });

      state.statusZone = updatedStatusZones;
    });

    builder.addCase(reorderedStatusZones.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteStatusZone.fulfilled, (state: any, action: any) => {
      const { installation_zone_id, id } = action.payload;
      state.statusZone[installation_zone_id] = state.statusZone[installation_zone_id].filter((zone: any) => {
        return Number(zone.id) != Number(id);
      });
    });

    builder.addCase(deleteStatusZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export const { updateStatusZoneValues, setStatusZoneLoaded } = statusZoneSlice.actions;
export default statusZoneSlice.reducer;
