import React, { useState, useEffect, useRef, useMemo } from 'react';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFavoritesByConnectionId, updateFavorite } from '../../slices/favorites/thunk';
import { updateCalculatedParameter } from '../../slices/calculatedParameters/thunk';

import BreadCrumb from 'Components/Common/BreadCrumb';
import { Col, Container, Row, Spinner, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { Chart } from "chart.js";
import { parse, format, parseISO, addDays, endOfMonth, endOfWeek, eachDayOfInterval, getWeek, startOfMonth, eachWeekOfInterval, isValid, addMinutes, endOfYear, addMonths } from 'date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import { es, id } from 'date-fns/locale';
import { getDailyAverageRegistersBetweenDates, getRegistersBetweenDates, refreshFavoritesRecords } from '../../slices/registers/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import GeneralData from 'pages/Dashboard/GeneralData';
import TableContainer from 'Components/Common/TableContainerReactTable';
import { getParameterValuesByConnection } from "../../slices/parameterValues/thunk";
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import { setZoneSelection } from 'slices/registers/reducer';
import ModalHelp from 'Components/Common/Modals/ModalHelp';
import { title } from 'process';

interface Register {
    register_id: number;
    connection_name: string;
    favorite_id: number;
    name: string;
    register_timestamp: string;
    register_value: number;
    zone_name: string;
    color: string;
}

interface Option {
    label: string;
    value: string;
}

type HistoricalLineChartProps = {
    setRegistersCache: (registers: any) => void;
    zoneId: string;
}

interface Dataset {
    label: string;
    data: number[];
    hidden?: boolean;
}

interface VisibleData {
    datasets: Dataset[];
    labels: string[];
}

const HistoricalLineChart = ({ setRegistersCache, zoneId }: HistoricalLineChartProps) => {
    const { id }: any = useParams<{ id: string }>();
    const [dateFilterChart, setFilter] = useState("day");
    const colorMapRef = useRef<{ [key: string]: string }>({});
    const [intervalMinutes, setIntervalMinutes] = useState(15);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [labels, setLabels] = useState<any[]>([]);
    const [data, setData] = useState<any>({
        labels: [],
        datasets: [],
    });

    const [loading, setLoading] = useState(false);
    const visibleDataRef = useRef<VisibleData>({ datasets: [], labels: [] });

    const [favoritesData, setFavoritesData] = useState<any[]>([]);
    const [calculatedVariablesData, setCalculatedVariablesData] = useState<any[]>([]);

    const [monthsOptions, setMonthsOptions] = useState<Option[]>([]);
    const [weekOptions, setWeekOptions] = useState<Option[]>([]);
    const [dayOptions, setDayOptions] = useState<Option[]>([]);
    const [yearOptions, setYearOptions] = useState<Option[]>([]);
    const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
    const toggleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);
    const [currentMessageHelp, setCurrentMessageHelp] = useState<string>('');
    const [currentTitleHelp, setCurrentTitleHelp] = useState<string>('');

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const dispatch: any = useDispatch();

    const selectRegistersState = (state: any) => state.Registers;
    const selectFavoritesState = (state: any) => state.Favorites;
    const selectInstallationState = (state: any) => state.Installations;
    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectCalculatedParametersState = (state: any) => state.CalculatedParameters;
    const selectParameterValuesState = (state: any) => state.ParameterValues;

    const RegistersProperties = createSelector(
        selectRegistersState,
        (state: any) => state
    );

    const installationDataProperties = createSelector(
        selectInstallationState,
        (state: any) => state
    );

    const FavoritesProperties = createSelector(
        selectFavoritesState,
        (state: any) => state
    );

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state) => state
    );

    const calculatedParametersProperties = createSelector(
        selectCalculatedParametersState,
        (state) => state
    );

    const parameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state: any) => state
    );



    const { registers, selectedOptions } = useSelector(RegistersProperties);
    const { favorites } = useSelector(FavoritesProperties);
    const { installations } = useSelector(installationDataProperties);
    const { parametersBackup } = useSelector(parametersBackupProperties);
    const calculatedParameters = useSelector((state: any) => {
        const params = state.CalculatedParameters.calculatedParameters[Number(id)];
        return Array.isArray(params) ? params : [];
    });
    const { parameterValues } = useSelector(parameterValuesProperties);

    const zoneRegisters = registers[zoneId] || [];
    const zoneSelectedOptions = selectedOptions[zoneId] || [];

    const [selectionMode, setSelectionMode] = useState(zoneSelectedOptions.selectionMode || 'detailedBreakdown');
    const [selectedYear, setSelectedYear] = useState(zoneSelectedOptions.selectedYear || new Date().getFullYear().toString());
    const [selectedMonth, setSelectedMonth] = useState(zoneSelectedOptions.selectedMonth || "");
    const [selectedWeek, setSelectedWeek] = useState(zoneSelectedOptions.selectedWeek || "");
    const [selectedDay, setSelectedDay] = useState(zoneSelectedOptions.selectedDay || "");
    const chartRef = useRef<any>(null);

    useEffect(() => {
        if (chartRef.current && chartRef.current.resetZoom && zoneRegisters.length > 0) {
            chartRef.current.resetZoom();
        }
    }, [zoneRegisters]);


    const zoomOptions = {
        pan: {
            enabled: true,
            mode: "x",
        },
        zoom: {
            wheel: {
                enabled: true,
            },
            pinch: {
                enabled: true,
            },
            mode: "x",
        },
    };

    const [options, setOptions] = useState<object>({
        responsive: true,
        plugins: {
            legend: {
                position: "top",
                align: "start",
                labels: {
                    padding: 15,
                    box: {
                        padding: {
                            bottom: 20
                        }
                    }
                },
                onClick: (event: any, legendItem: any, legend: any) => {
                    const chart = legend.chart;
                    const datasetIndex = legendItem.datasetIndex;
                    const meta = chart.getDatasetMeta(datasetIndex);

                    meta.hidden = meta.hidden === null ? !chart.data.datasets[datasetIndex].hidden : null;

                    if (visibleDataRef.current.datasets[datasetIndex] !== undefined) {
                        visibleDataRef.current.datasets[datasetIndex].hidden = meta.hidden;
                    }

                    chart.update();
                }
            },
            zoom: dateFilterChart === "month" || dateFilterChart === "year" ? {} : zoomOptions,
        },
        scales: {
            x: {
                type: "category",
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: dateFilterChart === "day" ? 24 : dateFilterChart === "week" ? 7 : 0,
                }
            },
            y: {
                beginAtZero: false,
                ticks: {
                    callback: function (value: any, index: any, values: any) {
                        return value.toFixed(2);
                    }
                }
            }
        }
    });









    useEffect(() => {
        if (zoneRegisters.length > 0) {
            // Si hay registros, genera los datasets y las labels para la gráfica
            const datasets = Object.keys(recordsByFavorite).map(favoriteName => {
                const data = new Array(labels.length).fill(null);

                recordsByFavorite[favoriteName].forEach(record => {
                    const recordDate = new Date(record.register_timestamp);
                    const adjustedDate = new Date(recordDate.getTime() - 3600000);

                    let formattedDate: any;
                    if (selectedDay !== "") {
                        formattedDate = format(adjustedDate, 'dd/MM/yyyy HH:mm');
                    } else if (selectedYear !== "" && selectedMonth === "" && selectedWeek === "" && selectedDay === "") {
                        formattedDate = format(adjustedDate, 'MM/yyyy');
                    } else {
                        formattedDate = format(adjustedDate, 'dd/MM/yyyy');
                    }

                    const index = labels.findIndex(label => label === formattedDate);
                    if (index !== -1) {
                        data[index] = record.register_value;
                    }


                });

                const color = recordsByFavorite[favoriteName][0].color;


                return {
                    label: favoriteName,
                    data: data,
                    fill: false,
                    backgroundColor: color,
                    borderColor: color,
                    radius: 2,
                    pointRadius: 3.5,
                    pointHoverRadius: 7
                };
            });

            const data = {
                labels: labels,
                datasets: datasets,
            };

            setData(data);
        }
    }, [zoneRegisters, labels]);

    useEffect(() => {
        return () => {
            setRegistersCache([]);
        };
    }, []);


    /*   useEffect(() => {
          if (!parameterValues || !parameterValues[Number(id)]) {
  
              dispatch(getParameterValuesByConnection(Number(id)));
          }
  
          if ((!parametersBackup || !parametersBackup[Number(id)]) && parameterValues[Number(id)]) {
  
              dispatch(getParametersBackup({ connectionId: Number(id), parameterValues: parameterValues[Number(id)] }));
          }
      }, [dispatch, id, parameterValues, parametersBackup]); */

    /*     useEffect(() => {
            if (parameterValues[Number(id)]) {
    
                dispatch(getFavoritesByConnectionId(Number(id)));
            }
        }, [dispatch, id, parameterValues, parametersBackup]); */

    useEffect(() => {
        if (parametersBackup[Number(id)] && parameterValues[Number(id)] && favorites[Number(id)]) {


            const combined = parametersBackup[Number(id)].parameters
                .map((backup: any) => {
                    const favorite = favorites[Number(id)]?.find((fav: any) => fav.parameter_id === backup.id);
                    if (favorite) {
                        return {
                            ...backup,
                            ...favorite,
                            value: backup.parameter_type === 'Visualization' ? '' : backup.value,
                            unit: backup.unit == '%' ? favorite.unit : backup.unit,  // Ensure unit comes from parametersBackup
                            parameter_id: backup.parameter_id,
                            connection_ip: parametersBackup[Number(id)].connection_ip,
                            device_range: parametersBackup[Number(id)].device_range
                        };
                    }
                    return null;
                })
                .filter((item: any) => item !== null);


            combined.sort((a: any, b: any) => a.position - b.position);

            const visualizationFavorites = combined.filter((fav: any) => fav.type === 'Visualization');
            const parametrizationFavorites = combined.filter((fav: any) => fav.type === 'Parametrization');

            setLoading(false);
        }
    }, [favorites, id, parametersBackup, parameterValues]);

    const [modalOpen, setModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<any>(null);
    const [itemToDeleteName, setItemToDeleteName] = useState<string>('');
    const [itemToDeleteType, setItemToDeleteType] = useState<string>('');

    const toggleModal = () => setModalOpen(!modalOpen);

    const toggleSelectionMode = () => {
        if (selectionMode === 'specificDate') {
            setSelectionMode('detailedBreakdown');
            setStartDate('');
            setEndDate('');
        } else {
            setSelectionMode('specificDate');
            setSelectedYear('');
            setSelectedMonth('');
            setSelectedWeek('');
            setSelectedDay('');
        }
    };

    useEffect(() => {
        visibleDataRef.current = data;

    }, [data]);

    useEffect(() => {
        Chart.register(zoomPlugin);
    }, []);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2023;
        const length = currentYear - startYear + 1;

        const yearOptions = [{ label: "Selecciona el año", value: "" }];

        const years = Array.from({ length }, (_, i) => `${currentYear - i}`)
            .sort((a, b) => parseInt(a) + parseInt(b))
            .map(year => ({ label: year, value: year }));

        setYearOptions([{ label: "Selecciona el año", value: "" }, ...years]);
        setYearOptions([...yearOptions, ...years]);
        setSelectedYear('');
    }, []);

    useEffect(() => {
        const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        const allMonthsOption = { label: "Todos los meses", value: "" };
        const monthsOptions = monthNames.map((name, index) => ({
            label: name,
            value: (index + 1).toString().padStart(2, '0'),
        }));
        setMonthsOptions([allMonthsOption, ...monthsOptions]);
    }, []);

    useEffect(() => {
        setWeekOptions([{ label: "Todas las semanas", value: "" }]);
        if (selectedMonth && selectedYear) {
            const monthIndex = parseInt(selectedMonth) - 1;
            const year = parseInt(selectedYear);
            const startDate = startOfMonth(new Date(year, monthIndex));
            const endDate = endOfMonth(new Date(year, monthIndex));

            const weeks = eachWeekOfInterval(
                { start: startDate, end: endDate },
                { weekStartsOn: 1 }
            ).map((week) => ({
                label: `Semana ${getWeek(week, { weekStartsOn: 1, locale: es })} (${format(week, 'dd/MM')} - ${format(endOfWeek(week, { weekStartsOn: 1 }), 'dd/MM')})`,
                value: format(week, 'yyyy-MM-dd'),
            }));

            setWeekOptions([{ label: "Todas las semanas", value: "" }, ...weeks]);
        }
    }, [selectedMonth, selectedYear]);

    useEffect(() => {
        setDayOptions([{ label: "Todos los días", value: "" }]);
        if (selectedWeek !== '' && selectedMonth && selectedYear) {
            const startDate = parseISO(selectedWeek);
            const endDate = endOfWeek(startDate, { weekStartsOn: 1 });

            const days = eachDayOfInterval({ start: startDate, end: endDate })
                .map(day => ({
                    label: format(day, 'EEEE dd/MM/yyyy', { locale: es }),
                    value: format(day, 'yyyy-MM-dd'),
                }));

            setDayOptions([{ label: "Todos los días", value: "" }, ...days]);
        }
    }, [selectedWeek, selectedMonth, selectedYear]);

    useEffect(() => {
        if (selectedYear == '') {
            setSelectedMonth('');
            setSelectedWeek('');
            setSelectedDay('');
        }

        if (selectedMonth == '') {
            setSelectedWeek('');
            setSelectedDay('');
        }

        if (selectedWeek == '') {
            setSelectedDay('');
        }
    }, [selectedYear, selectedMonth, selectedWeek, selectedDay]);

    const colorMap: { [key: string]: string } = {};

    const predefinedColors = [
        "rgb(229, 68, 68)",
        "rgb(229, 117, 68)",
        "rgb(229, 165, 68)",
        "rgb(229, 213, 68)",
        "rgb(197, 229, 68)",
        "rgb(149, 229, 68)",
        "rgb(100, 229, 68)",
        "rgb(68, 229, 84)",
        "rgb(68, 229, 133)",
        "rgb(68, 229, 181)",
        "rgb(68, 229, 229)",
        "rgb(68, 181, 229)",
        "rgb(68, 133, 229)",
        "rgb(68, 84, 229)",
        "rgb(100, 68, 229)",
        "rgb(149, 68, 229)",
        "rgb(197, 68, 229)",
        "rgb(229, 68, 213)",
        "rgb(229, 68, 165)",
        "rgb(229, 68, 117)"
    ];


    const recordsByFavorite = useMemo(() => {
        if (!Array.isArray(zoneRegisters)) {
            return {};
        }

        return zoneRegisters.reduce<Record<string, Register[]>>((groups, record) => {
            let groupName = `${record.name || "Nombre desconocido"} - ${record.connection_name || "Conexión desconocida"}`;
            const group = (groups[groupName] = groups[groupName] || []);
            group.push(record);
            return groups;
        }, {});
    }, [zoneRegisters]);

    const generateDateLabels = (
        startDate: string,
        endDate: string,
        selectedDay: string,
        selectedWeek: string,
        selectedMonth: string,
        selectedYear: string
    ): string[] => {
        const labels: string[] = [];

        if (startDate && endDate) {
            const start = parseISO(startDate);
            const end = parseISO(endDate);

            eachDayOfInterval({ start, end }).forEach(day => {
                const label = format(day, 'dd/MM/yyyy'); // Etiqueta solo por día
                labels.push(label);
            });
        } else if (selectedDay) {
            const startOfDay = parseISO(selectedDay);
            for (let minute = 0; minute < 1440; minute++) { // 1440 minutos en un día
                const label = format(addMinutes(startOfDay, minute), 'dd/MM/yyyy HH:mm');
                labels.push(label);
            }
        } else if (selectedWeek) {
            const startOfWeek = parseISO(selectedWeek);
            const endOfWeekDate = endOfWeek(startOfWeek, { weekStartsOn: 1 });
            eachDayOfInterval({ start: startOfWeek, end: endOfWeekDate }).forEach(day => {
                const label = format(day, 'dd/MM/yyyy');
                labels.push(label);
            });
        } else if (selectedMonth && selectedYear) {
            // Generar etiquetas para el mes seleccionado
            const startOfMonthDate = parseISO(`${selectedYear}-${selectedMonth}-01`);
            const endOfMonthDate = endOfMonth(startOfMonthDate);
            eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate }).forEach(day => {
                const label = format(day, 'dd/MM/yyyy');
                labels.push(label);
            });
        } else if (selectedYear) {
            // Generar etiquetas para el año seleccionado, por mes
            const startOfYearDate = parseISO(`${selectedYear}-01-01`);
            const endOfYearDate = endOfYear(startOfYearDate);
            for (let month = 0; month < 12; month++) {
                const label = format(addMonths(startOfYearDate, month), 'MM/yyyy');
                labels.push(label);  // Generamos etiquetas para cada mes del año
            }
        }

        return labels;
    };




    const fetchRegisters = async () => {


        if (startDate === '' && endDate === '' && selectionMode === 'specificDate') {
            alert('La fecha de inicio y la fecha de fin no pueden estar vacías.');
            return;
        }

        if (startDate === '' &&
            endDate === '' &&
            selectedYear === '' &&
            selectedMonth === '' &&
            selectedWeek === '' &&
            selectedDay === '' &&
            selectionMode === 'detailedBreakdown') {
            alert('Selecciona al menos un filtro de fecha.');
            return;
        }

        // Validación de fechas correctas
        if (startDate && endDate && parseISO(startDate) > parseISO(endDate)) {
            alert('La fecha de inicio no puede ser posterior a la fecha de fin.');
            return;
        }

        setLoading(true);
        let data: Register[] = [];

        try {
            if (startDate && endDate && selectionMode === 'specificDate') {
                const formattedStartDate = format(parseISO(startDate), 'yyyy-MM-dd');
                const formattedEndDate = format(parseISO(endDate), 'yyyy-MM-dd');

                // Hacemos la petición para obtener registros entre las fechas
                data = await dispatch(getDailyAverageRegistersBetweenDates({ startDate: formattedStartDate, endDate: formattedEndDate, zoneId })).unwrap();
            } else if (selectedDay !== '') {
                const formattedStartDate = selectedDay;
                const endDate = addDays(parseISO(selectedDay), 1);
                const formattedEndDate = format(endDate, 'yyyy-MM-dd');
                data = await dispatch(getRegistersBetweenDates({ startDate: formattedStartDate, endDate: formattedEndDate, zoneId })).unwrap();
            } else if (selectedWeek) {
                const startDate = parseISO(selectedWeek);
                const endDate = addDays(endOfWeek(startDate, { weekStartsOn: 1 }), 1);
                const formattedStartDate = format(startDate, 'yyyy-MM-dd');
                const formattedEndDate = format(endDate, 'yyyy-MM-dd');
                const response = await dispatch(getDailyAverageRegistersBetweenDates({ startDate: formattedStartDate, endDate: formattedEndDate, zoneId }));
                data = response.payload;
            } else if (selectedMonth && selectedYear) {
                const startDate = parseISO(`${selectedYear}-${selectedMonth}-01`);
                const endDate = endOfMonth(startDate);
                const formattedStartDate = format(startDate, 'yyyy-MM-dd');
                const formattedEndDate = format(endDate, 'yyyy-MM-dd');
                const response = await dispatch(getDailyAverageRegistersBetweenDates({ startDate: formattedStartDate, endDate: formattedEndDate, zoneId }));
                data = response.payload;
            } else if (selectedYear) {
                const startDate = parseISO(`${selectedYear}-01-01`);
                const endDate = endOfYear(startDate);
                const formattedStartDate = format(startDate, 'yyyy-MM-dd');
                const formattedEndDate = format(endDate, 'yyyy-MM-dd');
                const response = await dispatch(getDailyAverageRegistersBetweenDates({ startDate: formattedStartDate, endDate: formattedEndDate, zoneId }));
                data = response.payload;
            }
        } catch (error) {
            console.error("Error fetching registers:", error);
        } finally {
            setLoading(false);
        }

        // Generar etiquetas incluso si no hay datos
        const newLabels = generateDateLabels(startDate, endDate, selectedDay, selectedWeek, selectedMonth, selectedYear);
        setLabels(newLabels);

        // Si no hay datos, crear datasets vacíos para que la gráfica no tenga líneas pero sí etiquetas en el eje x
        if (!data || data.length === 0) {
            const emptyDataset = {
                label: 'Sin datos',
                data: new Array(newLabels.length).fill(null),
                borderColor: 'rgba(0, 0, 0, 0.1)',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                fill: false,
            };
            setData({
                labels: newLabels,
                datasets: [emptyDataset],
            });
        } else {
            const datasets = Object.keys(recordsByFavorite).map(favoriteName => {
                const datasetData = new Array(newLabels.length).fill(null);

                recordsByFavorite[favoriteName].forEach(record => {
                    const recordDate = new Date(record.register_timestamp);
                    const adjustedDate = new Date(recordDate.getTime() - 3600000);
                    const formattedDate = selectedDay
                        ? format(adjustedDate, 'dd/MM/yyyy HH:mm')
                        : format(adjustedDate, 'dd/MM/yyyy');

                    const index = newLabels.findIndex(label => label === formattedDate);
                    if (index !== -1) {
                        datasetData[index] = record.register_value;
                    }
                });

                const color = recordsByFavorite[favoriteName][0].color;

                return {
                    label: favoriteName,
                    data: datasetData,
                    fill: false,
                    backgroundColor: color,
                    borderColor: color,
                    radius: 2,
                    pointRadius: 3.5,
                    pointHoverRadius: 7
                };
            });

            setData({
                labels: newLabels,
                datasets: datasets,
            });
        }
    };

    const handleStartDateChange = (date: string) => {
        setSelectedYear('');
        setSelectedMonth('');
        setSelectedWeek('');
        setSelectedDay('');
        setStartDate(date);
    };

    const handleEndDateChange = (date: string) => {
        setSelectedYear('');
        setSelectedMonth('');
        setSelectedWeek('');
        setSelectedDay('');
        setEndDate(date);
    };

    const convertToCSV = () => {
        if (!visibleDataRef.current || !visibleDataRef.current.datasets.length) {
            alert('No hay datos para descargar');
            return;
        }

        const visibleDatasets = visibleDataRef.current.datasets.filter((dataset: any) => !dataset.hidden);

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += ["Fecha", ...visibleDatasets.map((ds: any) => ds.label)].join(",") + "\n";

        visibleDataRef.current.labels.forEach((label: any, index: any) => {
            const row = [label, ...visibleDatasets.map((ds: any) => {
                const value = ds.data[index];
                return value !== undefined && value !== null ? value.toFixed(2) : "";
            })];
            csvContent += row.join(",") + "\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteCalculatedParameterClick = (calculatedParameter: any) => {
        setItemToDelete(calculatedParameter);
        setItemToDeleteName(calculatedParameter.name);
        setItemToDeleteType('calculatedParameter');
        setConfirmationModalOpen(true);
    };

    const handleDeleteFavoriteClick = (favorite: any) => {
        setItemToDelete(favorite);
        setItemToDeleteName(favorite.name);
        setItemToDeleteType('favorite');
        setConfirmationModalOpen(true);
    };

    const handleConfirmDelete = async (idSelected: any) => {
        if (itemToDeleteType === 'calculatedParameter') {
            await dispatch(updateCalculatedParameter({ ...itemToDelete, register: false }));
            setCalculatedVariablesData(calculatedVariablesData.filter(param => param.id !== idSelected));
        } else if (itemToDeleteType === 'favorite') {
            await dispatch(updateFavorite({ ...itemToDelete, register: false }));
            setFavoritesData(favoritesData.filter(fav => fav.id !== idSelected));
        }

        setConfirmationModalOpen(false);
    };

    const handleCancelDelete = () => {
        setConfirmationModalOpen(false);
    };

    useEffect(() => {
        const filteredConnections = installations.flatMap((installation: any) =>
            installation.zones.flatMap((zone: any) =>
                zone.connections.filter((connection: any) => connection.zone_id === parseInt(id))
            )
        );


        const filteredConnectionIds = filteredConnections.map((connection: any) => connection.id);

        const filteredFavorites: any = [];
        filteredConnectionIds.forEach((connectionId: any) => {
            if (!favorites[connectionId]) return;
            const connectionFavorites = favorites[connectionId].filter((favorite: any) =>
                favorite.connection_id == connectionId && favorite.register == true
            );

            const connectionName = parametersBackup[connectionId]?.connection_name;
            const updatedFavorites = connectionFavorites.map((favorite: any) => ({
                ...favorite,
                connection_name: connectionName,
            }));

            filteredFavorites.push(...updatedFavorites);
        });

        const favoritesData = filteredFavorites.map((favorite: any) => ({
            connection_name: favorite.connection_name,
            ...favorite

        }));
        setFavoritesData(favoritesData);



    }, [favorites, parametersBackup]);

    useEffect(() => {

        const filteredCalculatedParameters = calculatedParameters.filter((param: any) => param.register === true);
        setCalculatedVariablesData(filteredCalculatedParameters);

    }, [calculatedParameters]);



    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy HH:mm', { locale: es });
    };

    const columnsCalculatedParameters = useMemo(() => [
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Fecha de registro', accessor: 'start_register_at', Cell: ({ value }: { value: string }) => formatDate(value) },
        {
            Header: 'Acciones',
            accessor: 'actions',
            Cell: ({ row }: { row: any }) => (
                <button
                    type="button"
                    className="btn btn-danger btn-sm d-flex"
                    onClick={() => handleDeleteCalculatedParameterClick(row.original)}
                >
                    <i className="ri-delete-bin-6-line" />
                </button>
            )
        }
    ], []);

    /*   useEffect(() => {
          // Verifica si ya tienes registros y opciones seleccionadas.
          if (zoneRegisters.length > 0) {
              // Aplica la lógica de fetchRegisters pero usando los valores de zoneSelectedOptions
              const selectedDay = zoneSelectedOptions.selectedDay || "";
              const selectedWeek = zoneSelectedOptions.selectedWeek || "";
              const selectedMonth = zoneSelectedOptions.selectedMonth || "";
              const selectedYear = zoneSelectedOptions.selectedYear || "";
              const selectionMode = zoneSelectedOptions.selectionMode || "detailedBreakdown";
              const selectedStartDate = zoneSelectedOptions.startDate || "";
              const selectedEndDate = zoneSelectedOptions.endDate || "";
  
  
              setSelectedDay(selectedDay);
              setSelectedWeek(selectedWeek);
              setSelectedMonth(selectedMonth);
              setSelectedYear(selectedYear);
              setSelectionMode(selectionMode);
  
              // Maneja el caso de fechas específicas
              setStartDate(selectedStartDate);
              setEndDate(selectedEndDate);
  
              // Genera etiquetas y datasets basados en los registros ya disponibles.
              const newLabels = generateDateLabels(
                  selectedStartDate || startDate,
                  selectedEndDate || endDate,
                  selectedDay,
                  selectedWeek,
                  selectedMonth,
                  selectedYear
              );
              setLabels(newLabels);
  
              const datasets = Object.keys(recordsByFavorite).map(favoriteName => {
                  const datasetData = new Array(newLabels.length).fill(null);
  
                  recordsByFavorite[favoriteName].forEach(record => {
                      const recordDate = new Date(record.register_timestamp);
                      console.log("Record Date: ", recordDate);
                      const formattedDate = selectedDay
                          ? format(recordDate, 'dd/MM/yyyy HH:mm')
                          : format(recordDate, 'dd/MM/yyyy');
  
                      const index = newLabels.findIndex(label => label === formattedDate);
                      console.log("index", index);
                      if (index !== -1) {
                          datasetData[index] = record.register_value;
                          console.log("datasetData: ", datasetData);
                      }
                  });
  
                  const color = recordsByFavorite[favoriteName][0].color;
  
                  return {
                      label: favoriteName,
                      data: datasetData,
                      fill: false,
                      backgroundColor: color,
                      borderColor: color,
                      radius: 2,
                      pointRadius: 3.5,
                      pointHoverRadius: 7
                  };
              });
  
              setData({
                  labels: newLabels,
                  datasets: datasets,
              });
          }
      }, []); */

    const columnsFavorites = useMemo(() => [
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Fecha de registro', accessor: 'start_register_at', Cell: ({ value }: { value: string }) => formatDate(value) },
        { Header: 'Dispositivo', accessor: 'connection_name' },
        {
            Header: 'Acciones',
            accessor: 'actions',
            Cell: ({ row }: { row: any }) => (
                <button
                    type="button"
                    className="btn btn-danger btn-sm d-flex"
                    onClick={() => handleDeleteFavoriteClick(row.original)}
                >
                    <i className="ri-delete-bin-6-line" />
                </button>
            )
        }
    ], []);

    return (
        <div className='container-fluid d-flex flex-column justify-content-between' style={{ height: '100vh' }}>
            <div className='d-flex justify-content-center mt-3'>
                <Row>
                    <Col md={4} className="d-flex justify-content-center mb-md-3">
                        <Button
                            onClick={() => {
                                setCurrentTitleHelp('Ayuda consulta de registros');
                                setCurrentMessageHelp(
                                    `<p>Podrás consultar los datos que tengas registrados desde favoritos. Para hacerlo, sigue estos pasos:</p>
                                     <ol>
                                       <li style="margin-bottom: 10px;"><strong>SELECCIÓN DE FECHAS:</strong>
                                         <ul>
                                           <li>Elige un rango (día, semana, mes o año) desde el desplegable.</li>
                                           <li>Si seleccionas un día completo, los registros se mostrarán cada 1 minuto. En las otras opciones, se verá la media diaria.</li>
                                           <li>Para seleccionar una fecha específica, usa el botón "Cambiar a fecha específica".</li>
                                         </ul>
                                       </li>
                                       <li style="margin-bottom: 10px;"><strong>BUSCAR REGISTROS:</strong>
                                         <ul>
                                           <li>Tras elegir la fecha, haz clic en "Buscar Registros" para visualizar los datos.</li>
                                         </ul>
                                       </li>
                                       <li style="margin-bottom: 10px;"><strong>DESCARGAR CSV:</strong>
                                         <ul>
                                           <li>Haz clic en "Descargar CSV" para obtener los registros en un archivo.</li>
                                         </ul>
                                       </li>
                                     </ol>`
                                );

                                toggleHelpModal();
                            }}
                            style={{
                                border: 'none',
                                background: 'none',
                                padding: 0,
                                outline: 'none',
                                boxShadow: 'none'
                            }}
                            className="btn-icon"
                        >
                            <i className="mdi mdi-help-circle text-primary fs-20"></i>
                        </Button>
                        <ModalHelp
                            isOpen={isHelpModalOpen}
                            toggle={toggleHelpModal}
                            title={currentTitleHelp}
                            body={currentMessageHelp}
                            buttonText="Cerrar"
                        />
                        <Button onClick={toggleSelectionMode} className="  btn rounded-pill btn-primary font-weight-bold px-4 w-100 mb-3 mb-md-0 p-0">
                            Cambiar a {selectionMode === 'specificDate' ? 'Desglose Detallado' : 'Fecha Específica'}
                        </Button>
                    </Col>
                    <Col md={4} className="d-flex justify-content-center mb-md-3">
                        <div className='d-md-flex align-items-center w-100 mb-3 mb-md-0'>
                            <div className='d-flex justify-content-center' style={{ marginRight: '10px' }}>Desde</div>
                            <input
                                type="date"
                                onChange={(e) => handleStartDateChange(e.target.value)}
                                value={startDate}
                                className={`mx-2 rounded-pill form-control ${selectionMode === 'detailedBreakdown' ? 'cursor-not-allowed bg-gray-400' : ''}`}
                                disabled={selectionMode === 'detailedBreakdown'}
                            />
                        </div>
                    </Col>
                    <Col md={4} className="d-flex justify-content-center mb-md-3">
                        <div className='d-md-flex align-items-center w-100'>
                            <div className='d-flex justify-content-center' style={{ marginRight: '10px' }}>Hasta</div>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => handleEndDateChange(e.target.value)}
                                className={`mx-2 rounded-pill form-control ${selectionMode === 'detailedBreakdown' ? 'cursor-not-allowed bg-gray-400' : ''}`}
                                disabled={selectionMode === 'detailedBreakdown'}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='d-flex justify-content-center align-items-center py-4'>
                <Row>
                    <Col md={6} className="d-md-flex justify-content-center mb-md-3">
                        <select
                            value={selectedYear}
                            style={{ minWidth: '150px' }}
                            onChange={(e) => {
                                setSelectedYear(e.target.value);
                                setSelectedMonth('');
                                setSelectedWeek('');
                                setSelectedDay('');
                            }}
                            className='form-control mb-2 mx-2 rounded-pill'
                            disabled={selectionMode === 'specificDate' ? true : false}
                        >
                            {yearOptions.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <select
                            value={selectedMonth}
                            style={{ minWidth: '150px' }}
                            disabled={!selectedYear || selectionMode === 'specificDate'}
                            onChange={(e) => {
                                setSelectedMonth(e.target.value);
                                setSelectedWeek('');
                                setSelectedDay('');
                            }}
                            className='form-control rounded-pill mb-2 mx-2'
                        >
                            {monthsOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </Col>
                    <Col md={6} className="d-md-flex justify-content-center mb-md-3">
                        <select
                            style={{ minWidth: '150px' }}
                            value={selectedWeek}
                            disabled={!selectedMonth || selectionMode === 'specificDate'}
                            onChange={(e) => {
                                setSelectedWeek(e.target.value);
                                setSelectedDay('');
                            }}
                            className='form-control rounded-pill mb-2 mx-2'
                        >
                            {weekOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <select
                            value={selectedDay}
                            disabled={!selectedWeek || selectionMode === 'specificDate'}
                            onChange={(e) => setSelectedDay(e.target.value)}
                            className='rounded-pill form-control mb-2 mx-2'
                        >
                            {dayOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </div>
            <div className='d-md-flex justify-content-center align-items-center mb-2'>
                <div className='d-flex justify-content-center mb-3 mb-md-0'>
                    <button
                        onClick={() => {
                            dispatch(setZoneSelection({
                                zoneId,
                                selectedYear,
                                selectedMonth,
                                selectedWeek,
                                selectedDay,
                                startDate,
                                endDate,
                                selectionMode
                            }));

                            fetchRegisters();
                        }}
                        className="rounded-pill btn btn-primary mx-2 font-weight-bold py-2 px-4 focus:outline-none w-100"
                    >
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <Spinner />
                            </div>
                        ) : (
                            "Buscar registro"
                        )}
                    </button>
                </div>

                <div className='d-flex justify-content-center mb-3 mb-md-0'>
                    <button
                        onClick={() => convertToCSV()}
                        className="rounded-pill ml-4 mx-2 btn btn-secondary font-weight-bold py-2 px-4 focus:outline-none w-100"
                    >
                        Descargar CSV
                    </button>
                </div>
                <div className='d-flex justify-content-center mb-3 mb-md-0'>
                    <button
                        onClick={toggleModal}
                        className="rounded-pill ml-4 mx-2 btn btn-info font-weight-bold py-2 px-4 focus:outline-none w-100 "
                    >
                        Variables en registro
                    </button>
                </div>

            </div>
            <div className='flex-grow-1'>
                {data && options && (
                    <Line ref={chartRef} data={data} options={options} />
                )}
            </div>

            <Modal isOpen={modalOpen} toggle={toggleModal} size="xl">
                <ModalHeader toggle={toggleModal}>Variables registradas</ModalHeader>
                <ModalBody>
                    <div className="d-flex flex-column flex-md-row">
                        <div className="flex-fill p-2">
                            <h5>Variables Calculadas</h5>
                            <TableContainer columns={columnsCalculatedParameters} data={calculatedVariablesData || []} isPagination={true} isGlobalFilter={true} customPageSize={10} />
                        </div>
                        <div className="flex-fill p-2">
                            <h5>Favoritos</h5>
                            <TableContainer columns={columnsFavorites} data={favoritesData || []} isPagination={true} isGlobalFilter={true} customPageSize={10} />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ModalConfirm
                isOpen={confirmationModalOpen}
                toggle={() => setConfirmationModalOpen(!confirmationModalOpen)}
                title="Confirmar eliminación"
                message={`Escribe el nombre "${itemToDeleteName}" para confirmar la eliminación.`}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                iconName="mdi mdi-delete display-5 text-danger"
                idSelected={itemToDelete?.id}
                requireNameConfirmation={true}
                confirmationName={itemToDeleteName}
            />
        </div>
    );
};

const Registers = () => {
    document.title = "Registros | Ecentroserver";
    const { id } = useParams<{ id: string }>();

    const selectInstallationState = (state: any) => state.Installations;


    const installationDataProperties = createSelector(
        selectInstallationState,
        (state: any) => state
    );

    const setRegistersCache = (newRegisters: Register[]) => { };
    const { installations } = useSelector(installationDataProperties);

    const installation = installations?.find((inst: any) =>
        inst.zones.some((zone: any) => zone.id == id)
    );

    const zone = installation?.zones.find((zone: any) => zone.id == id);

    return (
        <React.Fragment>
            <BreadCrumb
                pageTitle={Array.isArray(installations) && installations
                    .find((installation: any) => installation.zones
                        .some((zone: any) => zone.id === Number(id) && zone.is_active))?.installation_name}
                pageTitleUrl={`/dashboard/instalacion/${installation && installation.id}`}

                subtitle={Array.isArray(installations) && installations
                    .flatMap((installation: any) => installation.zones)
                    .find((zone: any) => zone.id == Number(id) && zone.is_active)?.zone_name}

                subtitleUrl={`/dashboard/zona/${zone && zone.id}`}

                subsubtitle={"Registros"}
            />
            <div className="page-content">
                <Container fluid>
                    <Col lg={12} className="order-xxl-0 order-first">
                        <GeneralData />
                    </Col>
                    <Row>
                        <Col xs={12}>
                            <HistoricalLineChart setRegistersCache={setRegistersCache} zoneId={id ?? "defaultZoneId"} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Registers;
