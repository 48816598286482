import { createSlice } from "@reduxjs/toolkit";
import { getFaultHistoriesNotAcknowledged, updateFaultHistory, getFaultHistoriesByZone } from "./thunk";

export const initialState: any = {
  faultHistories: [],
  faultHistoryNotAcknowledged: [],
  activeFaults: [],
  error: null,
  loading: false,
};

const faultHistorySlice = createSlice({
  name: "faultHistory",
  initialState,
  reducers: {
    addActiveFault: (state, action: any) => {
      const fault = action.payload;
      console.log("faulta no acusada antes del array", fault);
    
      // Verifica si ya existe el fault_history_id en el array de activeFaults
      const exists = state.activeFaults.some(
        (existingFault: any) => existingFault.fault_history_id === fault.fault_history_id
      );
    
      // Si no existe y no tiene la propiedad 'alert_name', añade el fault
      if (!exists && !fault.alarm_name) {
        console.log("faulta añadida al array", fault);
        state.activeFaults = [...state.activeFaults, fault];
      } else {
        console.log("faulta no añadida al array", fault);
      }
    },

    resetActiveFaults: (state) => {
      state.activeFaults = [];
    },
    
  },

  extraReducers: (builder) => {
    builder.addCase(getFaultHistoriesNotAcknowledged.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFaultHistoriesNotAcknowledged.fulfilled, (state, action) => {
      state.loading = false;
      state.faultHistoryNotAcknowledged = action.payload;
    });
    builder.addCase(getFaultHistoriesNotAcknowledged.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch fault histories';
    });

    builder.addCase(getFaultHistoriesByZone.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFaultHistoriesByZone.fulfilled, (state, action) => {
      state.loading = false;
      state.faultHistories = action.payload;
    });
    builder.addCase(getFaultHistoriesByZone.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch alarm histories by zone';
    });

    builder.addCase(updateFaultHistory.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.activeFaults = state.activeFaults.filter(
          (fault: any) => fault.fault_history_id !== action.payload.id
        );
      }
    });
  },
});

export const {addActiveFault, resetActiveFaults} = faultHistorySlice.actions;
export default faultHistorySlice.reducer;
