import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Col, Container, Spinner, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, Input, Label } from 'reactstrap';
import { getFavoritesByConnectionId, deleteFavorite, reorderFavorites, updateFavorite, getLinkedFavorites, getLinkedVariablesByConnection, isAnalogicUsed, resetFavoriteSelected } from 'slices/favorites/thunk';
import { ToastContainer, toast } from 'react-toastify';
import { getParameterValuesByConnection } from 'slices/parameterValues/thunk';
import { updateParameterBackup } from 'slices/parametersBackup/thunk';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import BreadCrumb from 'Components/Common/BreadCrumb';
import GeneralData from 'pages/Dashboard/GeneralData';
import TableContainerFavorites from './TableContainerReactTableFavorites';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import { SocketContext } from 'context/SocketProvider';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import ModalLinkedParameters from './ModalLinkedParameters';
import { setValueChanged } from 'slices/parametersBackup/reducer';
import rolesIds from 'constants/roles';
import ModalHelp from 'Components/Common/Modals/ModalHelp';
import favoriteVisualizationHelp from 'assets/images/help_visualization_favorite.png'

const Favorites = () => {
    document.title = "Favoritos | EcentroServer";

    const dispatch: any = useDispatch();
    const { id } = useParams();
    const socket = useContext(SocketContext);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [parametersModalOpen, setParametersModalOpen] = useState(false);
    const [selectedFavoriteId, setSelectedFavoriteId] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [hasEmitted, setHasEmitted] = useState(false);
    const [visualizationData, setVisualizationData] = useState([]);
    const [parametrizationData, setParametrizationData] = useState([]);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [selectedType, setSelectedType] = useState('favorites');
    const connectionID = Number(id)
    const [analogicResponse, setAnalogicResponse] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [sidebarData, setSidebarData] = useState<any>("");
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
    const [currentMessageHelp, setCurrentMessageHelp] = useState<string>('Aquí podrás gestionar los favoritos, visualización y parametrización.');
    const [currentTitleHelp, setCurrentTitleHelp] = useState<string>('Ayuda de favoritos');
    const [unitOptions, setUnitOptions] = useState<{ value: string; label: string }[]>([]);
    const [matchValues, setMatchValues] = useState<any>({});


    const toggleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);

    const selectFavoritesState = (state: any) => state.Favorites;
    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const selectInstallationsState = (state: any) => state.Installations;

    const FavoritesProperties = createSelector(
        selectFavoritesState,
        (state: any) => state
    );

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state: any) => state
    );

    const parameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state: any) => state
    );

    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state) => state
    );


    const profileUserData = createSelector(
        (state: any) => state.Profile.user,
        (user) => user
    );

    const user = useSelector(profileUserData);


    const { parameterValues } = useSelector(parameterValuesProperties);
    const { parametersBackup } = useSelector(parametersBackupProperties);
    const { favorites } = useSelector(FavoritesProperties);
    const { linkedFavorites, favoriteIdSelected } = useSelector(FavoritesProperties);
    const { installations } = useSelector(installationDataProperties);


    /*  useEffect(() => {
         if (!parameterValues || !parameterValues[Number(id)]) {
             dispatch(getParameterValuesByConnection(Number(id)));
         }
 
         if ((!parametersBackup || !parametersBackup[Number(id)]) && parameterValues[Number(id)]) {
             dispatch(getParametersBackup({ connectionId: Number(id), parameterValues: parameterValues[Number(id)] }));
         }
     }, [dispatch, id, parameterValues, parametersBackup]); */

    /*     useEffect(() => {
            if (parameterValues[Number(id)]) {
                dispatch(getFavoritesByConnectionId(Number(id)));
            }
        }, [dispatch, id, parameterValues, parametersBackup]); */

    useEffect(() => {
        const fetchData = async () => {
            if (favoriteIdSelected !== null && favorites[Number(id)]) {
                // Buscar el favorito por su ID en favorites
                const selectedFavorite = favorites[Number(id)].find((fav: any) => fav.id == favoriteIdSelected);



                if (selectedFavorite && parametersBackup[Number(id)]) {

                    const selectedBackup = parametersBackup[Number(id)]?.parameters?.find(
                        (param: any) => param.id == selectedFavorite.parameter_id
                    );



                    if (selectedBackup) {

                        const combinedFavorite = {
                            ...selectedBackup,
                            ...selectedFavorite,
                            parameter_id: selectedBackup.parameter_id,
                        };


                        await fetchAnalogicUsage(
                            combinedFavorite.unit,
                            combinedFavorite.parameter_id,
                            combinedFavorite.connection_ip,
                            combinedFavorite.device_range
                        );

                        setSelectedRow(combinedFavorite);
                        setActionModalOpen(true);
                    }
                }
            }
        };

        fetchData();

    }, [favoriteIdSelected, favorites, parametersBackup, id]);


    useEffect(() => {
        if (parametersBackup[Number(id)] && parameterValues[Number(id)] && favorites[Number(id)]) {

            const combined = parametersBackup[Number(id)].parameters
                .map((backup: any) => {

                    const favorite = favorites[Number(id)]?.find((fav: any) => fav.parameter_id === backup.id);
                    if (favorite) {
                        return {
                            ...backup,
                            ...favorite,
                            value: backup.parameter_type === 'Visualization' ? backup.value : backup.value,
                            unit: backup.unit == '%' && backup.variable.startsWith("Entrada") ? favorite.unit : backup.unit,
                            parameter_id: backup.parameter_id,
                            connection_ip: parametersBackup[Number(id)].connection_ip,
                            device_range: parametersBackup[Number(id)].device_range
                        };
                    }
                    return null;
                })
                .filter((item: any) => item !== null);


            combined.sort((a: any, b: any) => a.position - b.position);

            const visualizationFavorites = combined.filter((fav: any) => fav.type === 'Visualization');
            const parametrizationFavorites = combined.filter((fav: any) => fav.type === 'Parametrization');


            setVisualizationData(visualizationFavorites);
            setParametrizationData(parametrizationFavorites);
            setLoading(false);
        }
    }, [favorites, id, parametersBackup, parameterValues]);


    useEffect(() => {
        if (socket) {
            socket.on('changeValueResponse', (response) => {
                if (response.status === 'success') {
                    toast.success("Se ha modificado el parámetro correctamente.");
                    const parameter = parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.parameter_id == response.id.toString());
                    const newParametersBackup = parametersBackup[Number(id)].parameters.map((parameter: any) => {
                        if (parameter.parameter_id == response.id.toString()) {
                            return { ...parameter, value: response.value };
                        }
                        return parameter;
                    });

                    const parameterInfo = {
                        connection_id: Number(id),
                        parameter_id: response.id,
                        value: response.value,
                        parameterValues: parameterValues[Number(id)][parameter.id],
                        parametersBackup: newParametersBackup
                    };

                    dispatch(updateParameterBackup(parameterInfo));
                } else {
                    toast.error("Hubo un error a la hora de modificar el parámetro");
                }
            });
        }

        return () => {
            if (socket) {
                socket.off('changeValueResponse');
            }
        };
    }, [socket, parametersBackup, parameterValues]);


    useEffect(() => {
        // Código que ejecutas en el montaje...

        // Al desmontar el componente, reseteamos el favoriteIdSelected
        return () => {
            dispatch(resetFavoriteSelected());  // Restablece el favoriteIdSelected a null al desmontar
        };
    }, [dispatch]);

    useEffect(() => {
        if (visualizationData.length > 0 && !hasEmitted) {
            const registers = visualizationData.map((value: any) => ({
                parameter_id: value.parameter_id,
                min: value.min,
                max: value.max,
                is_active: value.is_active,
                bit_parameter_id: value.bit_parameter_id,
                idmap_id: value.idmap_id,
                unit: value.unit,
                min_scale: value.min_scale,
                max_scale: value.max_scale,
                connection_id: value.connection_id,
            }));
            const ip = parametersBackup[Number(id)].connection_ip;


            setSidebarData({ [ip]: registers });

            /*  if (socket) {
                 socket.emit('start_modbus_values', { ip, connectionId: Number(id), registers });
                 setHasEmitted(true);
             } */
        } else if (visualizationData.length === 0 && socket) {
            /*   socket.emit("handle_stop_modbus_values"); */
        }


    }, [visualizationData, socket, parametersBackup, id, hasEmitted]);

    useEffect(() => {
        if (socket) {
            socket.on('send_modbus_values_multiples_ips', (data) => {
                if (data && data.values && parametersBackup) {

                    updateVisualizationData(data.values, parametersBackup, parameterValues);
                }
            });

            return () => {
                socket.off('send_modbus_values_multiples_ips');
                /* socket.emit('stop_modbus_values_multiples_ips'); */
            };
        }
    }, [socket, parametersBackup, favorites]);


    const handleUnitChange = (event: any) => {
        const { value } = event.target;

        setSelectedRow((prevRow: any) => ({ ...prevRow, unit: value }));

        let found = false;
        for (const key in matchValues) {
            if (matchValues[key].unit_text === value) {
                const { min_unit, max_unit, decimal_numbers } = matchValues[key];
                const factor = Math.pow(10, decimal_numbers);

                const minScale = min_unit ? min_unit.read_value / factor : '';
                const maxScale = max_unit ? max_unit.read_value / factor : '';

                setSelectedRow((prevRow: any) => ({
                    ...prevRow,
                    min_scale: minScale,
                    max_scale: maxScale,
                    decimal_count: decimal_numbers,
                }));

                found = true;
                break;
            }
        }

        if (!found) {
            setSelectedRow((prevRow: any) => ({
                ...prevRow,
                min_scale: '',
                max_scale: '',
                decimal_count: 0,
            }));
        }
    };


    const installation = installations?.find((inst: any) =>
        inst.zones.some((zone: any) => zone.connections.some((conn: any) => conn.id === connectionID))
    );
    const zone = installation?.zones.find((zone: any) =>
        zone.connections.some((conn: any) => conn.id === connectionID)
    );
    const connection = zone?.connections.find((conn: any) => conn.id === connectionID);

    const updateVisualizationData = (newValues: any, parametersBackup: any, parameterValues: any) => {
        setVisualizationData((prevData: any) =>
            prevData.map((item: any) => {

                const newValue = newValues.find((val: any) =>
                    Number(val.parameter_id) === Number(item.parameter_id) &&
                    val.connection_id === connection?.id
                );


                if (newValue) {
                    const parameterDetails = parametersBackup[newValue.connection_id].parameters?.find((p: any) =>
                        Number(p.parameter_id) === newValue.parameter_id
                    );

                    const maxDecimalPlaces = parameterDetails ?
                        Math.max(
                            countDecimals(parameterDetails.min),
                            countDecimals(parameterDetails.max)
                        ) : 0;

                    let adjustedValue = newValue.value;

                    // Comprobar si es necesario extraer un bit específico y hacerlo si es necesario
                    if (parameterDetails.bit_parameter_id || parameterDetails.bit_parameter_id == 0) {

                        // Asegura que bit_parameter_id es un número y luego realiza la operación de desplazamiento y extracción del bit
                        const bitIndex = parseInt(item.bit_parameter_id);
                        adjustedValue = (adjustedValue >> bitIndex) & 1;
                    }

                    // Aplicar escala si existe en los favoritos
                    const hasMaxScale = favorites[connection?.id]?.find((fav: any) => Number(fav.parameter_id) === Number(parameterDetails.parameter_id))?.max_scale;
                    if (hasMaxScale) {
                        const scale = parseFloat(hasMaxScale);
                        adjustedValue = (adjustedValue * scale) / 100;
                    }

                    // Formatear el valor ajustado a la cantidad de decimales requerida
                    adjustedValue = formatValue(adjustedValue, maxDecimalPlaces);

                    return { ...item, value: adjustedValue };
                }
                return item;
            })
        );
    };

    const countDecimals = (value: any) => {
        if (!value) return 0;
        const parts = value.toString().replace(',', '.').split('.');
        return parts.length > 1 ? parts[1].length : 0;
    };

    const formatValue = (value: any, decimals: any) => {
        return (parseFloat(value) / Math.pow(10, decimals)).toFixed(decimals);
    };



    const handleFavoriteButtonClick = (favoriteId: number) => {
        setSelectedFavoriteId(favoriteId);
        setConfirmModalOpen(true);
    };

    const handleConfirmDelete = async (favoriteId: number | null) => {
        if (favoriteId !== null) {
            try {
                await dispatch(deleteFavorite({ favoriteId, connectionId: Number(id) }));
                toast.success("Favorito eliminado correctamente.");
            } catch (error) {
                toast.error("Error al eliminar el favorito.");
            }
            setConfirmModalOpen(false);
            setSelectedFavoriteId(null);
        }
    };

    const handleVariableChange = (id: number, newValue: string) => {
        // Implementar cambio de variable si es necesario
    };

    useEffect(() => {
        if (!actionModalOpen) {

            setErrorMessage(null)
            setAnalogicResponse(null)
        }

    }, [actionModalOpen])

    useEffect(() => {
        if (favoriteIdSelected !== null) {
            setSelectedFavoriteId(favoriteIdSelected);
            setActionModalOpen(true);
        }
    }, [favoriteIdSelected]);

    const handleActionClick = async (row: any) => {
        setSelectedRow(row.original);
        await fetchAnalogicUsage(row.original.unit, row.original.parameter_id, row.original.connection_ip, row.original.device_range);
        setActionModalOpen(true);
    };

    const fetchAnalogicUsage = async (unit: any, parameterId: any, connectionIp: any, deviceRange: any) => {
        const response = await dispatch(isAnalogicUsed({
            ip: connectionIp,
            parameter_id: Number(parameterId),
            device: deviceRange
        }));

        if (response.payload) {
            const matchValues = response.payload;
            setMatchValues(matchValues);

            let minScale: any, maxScale: any, decimalNumbers: any, unitText: any;
            let units: any = [];

            const keyDescriptions: any = {
                "actual_value_1_ext_pid": "PID externo valor actual 1",
                "actual_value_1_pid": "PID valor actual 1",
                "actual_value_2_ext_pid": "PID externo valor actual 2",
                "actual_value_2_pid": "PID valor actual 2",
                "input_pressure_control": "supervisión presión de entrada"
            };

            for (const key in response.payload) {
                const item = response.payload[key];
                const factor = Math.pow(10, item.decimal_numbers);


                minScale = item.min_unit ? item.min_unit.read_value / factor : minScale;
                maxScale = item.max_unit ? item.max_unit.read_value / factor : maxScale;
                decimalNumbers = item.decimal_numbers || decimalNumbers;

                if (item.unit_text) {
                    unitText = item.unit_text;

                    let matchText = keyDescriptions[key] || key;

                    units.push({ key: matchText, unit: unitText });
                }
            }
            setAnalogicResponse({ minScale, maxScale, decimalNumbers, unitText });

            setUnitOptions(units.map((unit: any) => ({ value: unit.unit, label: `${unit.unit} (${unit.key})` })));


            let usages = [];
            if (matchValues["actual_value_1_ext_pid"]?.match) usages.push("PID externo valor actual 1");
            if (matchValues["actual_value_1_pid"]?.match) usages.push("PID valor actual 1");
            if (matchValues["actual_value_2_ext_pid"]?.match) usages.push("PID externo valor actual 2");
            if (matchValues["actual_value_2_pid"]?.match) usages.push("PID valor actual 2");
            if (matchValues["input_pressure_control"]?.match) usages.push("supervisión de presión de entrada");

            if (usages.length > 0) {
                let errorMessage = "Se ha detectado que la analógica está siendo usada en ";

                errorMessage += usages.length === 2
                    ? usages.join(" y ")
                    : usages.slice(0, -1).join(", ") + (usages.length > 2 ? ", y " : "") + usages[usages.length - 1];
                errorMessage += ".";
                setErrorMessage(errorMessage);
            } else {
                setErrorMessage(null);
            }
        }
    };


    const allColumnsVisualization = useMemo(
        () => [
            {
                Header: "Indice",
                accessor: "index",
                disableFilters: true,
                filterable: false,
                minWidth: '80px',
            },
            {
                Header: "Nombre",
                accessor: "name",
                disableFilters: true,
                filterable: false,
                minWidth: '220px',
            },
            {
                Header: "Valor",
                accessor: "value",
                disableFilters: true,
                filterable: false,
                minWidth: '120px',
            },
            {
                Header: "Unidad",
                accessor: "unit",
                disableFilters: true,
                filterable: false,
                minWidth: '30px',
            },
            {
                Header: "ID",
                accessor: "parameter_id",
                disableFilters: true,
                filterable: false,
                minWidth: '30px',
            },
            {
                Header: "Acciones",
                accessor: "actions",
                disableFilters: true,
                filterable: false,
                minWidth: '120px',
                Cell: ({ row }: any) => (
                    <div>
                        <button
                            disabled={user.role_id === rolesIds.operator}
                            type="button"
                            className="btn btn-primary btn-sm me-2"
                            onClick={() => handleActionClick(row)}
                        >
                            <i className="ri-settings-4-fill" />
                        </button>
                        <button
                            disabled={user.role_id === rolesIds.operator}
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleFavoriteButtonClick(row.original.id)}
                        >
                            <i className="ri-delete-bin-6-line" />
                        </button>
                    </div>
                ),
            }
        ],
        []
    );

    const allColumnsParametrization = useMemo(
        () => [
            {
                Header: "Indice",
                accessor: "index",
                disableFilters: true,
                filterable: false,
                minWidth: '80px',
            },
            {
                Header: "Nombre",
                accessor: "name",
                disableFilters: true,
                filterable: false,
                minWidth: '220px'
            },
            {
                Header: "Valor",
                accessor: "value",
                disableFilters: true,
                filterable: false,
                minWidth: '120px',
            },
            {
                Header: "Unidad",
                accessor: "unit",
                disableFilters: true,
                filterable: false,
                minWidth: '70px',
            },
            {
                Header: "ID",
                accessor: "parameter_id",
                disableFilters: true,
                filterable: false,
                minWidth: '70px',
            },
            {
                Header: "Acciones",
                accessor: "actions",
                disableFilters: true,
                filterable: false,
                minWidth: '120px',
                Cell: ({ row }: any) => (
                    <div>
                        <button
                            disabled={user.role_id === rolesIds.operator}
                            type="button"
                            className="btn btn-primary btn-sm me-2"
                            onClick={() => handleActionClick(row)}
                        >
                            <i className="ri-settings-4-fill" />
                        </button>
                        <button
                            disabled={user.role_id === rolesIds.operator}
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleFavoriteButtonClick(row.original.id)}
                        >
                            <i className="ri-delete-bin-6-line" />
                        </button>
                    </div>
                ),
            }
        ],
        [favorites, id, parametersBackup]
    );

    const newOrder = (result: any, type: string) => {
        if (type === 'Visualization') {
            setVisualizationData(result);
        } else {
            setParametrizationData(result);
        }

        // Actualizar el orden en el backend
        let updatedPositions = result.map((item: any, index: number) => ({
            id: item.id,
            position: index + 1
        }));

        let payload = {
            favorites: updatedPositions,
            connectionId: Number(id)
        };

        dispatch(reorderFavorites(payload));
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nombre personalizado es obligatorio'),
        min_scale: Yup.number()
            .test('all-or-none', 'Debe llenar escala mínima, máxima', function (value) {
                const { max_scale, unit } = this.parent;
                const allFilled = value !== undefined && value !== null && max_scale !== undefined && max_scale !== null;
                const allEmpty = value === undefined && max_scale === undefined;
                return allFilled || allEmpty;
            })
            .typeError('Escala mínima debe ser un número'),
        max_scale: Yup.number()
            .test('all-or-none', 'Debe llenar escala mínima, máxima', function (value) {
                const { min_scale, unit } = this.parent;
                const allFilled = value !== undefined && value !== null && min_scale !== undefined && min_scale !== null;
                const allEmpty = value === undefined && min_scale === undefined;
                return allFilled || allEmpty;
            })
            .typeError('Escala máxima debe ser un número'),

        decimal_count: Yup.number()
            .integer('Debe ser un número entero')
            .min(0, 'Debe ser un número entre 0 y 2')
            .max(2, 'Debe ser un número entre 0 y 2')
            .required('Campo requerido')

    });

    const ensureNotNull = (value: any) => {
        return value !== null ? value : '';
    };

    useEffect(() => {
        if (selectedRow?.id) {
            dispatch(getLinkedFavorites(selectedRow?.id));
        }
        dispatch(getLinkedVariablesByConnection(Number(id)));
    }, [dispatch, selectedRow]);

    const handleSubmit = async (values: any) => {
        dispatch(resetFavoriteSelected());
        // Convert empty string values to null
        const cleanedValues = Object.fromEntries(
            Object.entries(values).map(([key, value]) => [key, value === "" ? null : value])
        );

        const favoriteData = {
            ...cleanedValues,
            id: selectedRow.id,
            connection_id: Number(id),
        };

        try {
            const response = await dispatch(updateFavorite(favoriteData));

            toast.success("Favorito actualizado correctamente.");
            setActionModalOpen(false);
            setHasEmitted(false);
        } catch (error) {
            toast.error("Error al actualizar el favorito.");
        }
    };

    const colors = [
        { name: 'White', hex: '#FFFFFF' },
        { name: 'Yellow', hex: '#FFFF00' },
        { name: 'Light Blue', hex: '#ADD8E6' },
        { name: 'Light Coral', hex: '#F08080' },
        { name: 'Light Green', hex: '#90EE90' },
        { name: 'Gold', hex: '#FFD700' },
        { name: 'Orange', hex: '#FFA908' },
        { name: 'Medium Orchid', hex: '#BA55D3' },
        { name: 'Dark Orange', hex: '#FF680D' },
        { name: 'Pale Violet Red', hex: '#DB7093' },
        { name: 'Khaki', hex: '#F0E68C' },
        { name: 'Dark Green', hex: '#349966' },
        { name: 'Tomato', hex: '#FF6347' },
        { name: 'Orchid', hex: '#DA70D6' },
        { name: 'Light Sea Green', hex: '#20B2AA' },
        { name: 'Medium Slate Blue', hex: '#7B68EE' },
        { name: 'Lime Green', hex: '#32CD32' },
        { name: 'Sandy Brown', hex: '#F4A460' },
        { name: 'Crimson', hex: '#DC143C' },
        { name: 'Peach Puff', hex: '#FFDAB9' },
        { name: 'Plum', hex: '#DDA0DD' },
        { name: 'Light Salmon', hex: '#FFA07A' }
    ];


    return (
        <React.Fragment>
            <ModalConfirm
                isOpen={confirmModalOpen}
                toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                title="Eliminar favorito"
                message="¿Estás seguro de eliminar este favorito?"
                onConfirm={() => handleConfirmDelete(selectedFavoriteId)}
                onCancel={() => setConfirmModalOpen(false)}
                idSelected={selectedFavoriteId}
                iconName='ri-information-line display-5 text-warning'
            />
            <Modal isOpen={actionModalOpen} toggle={() => setActionModalOpen(!actionModalOpen)} backdrop="static" keyboard={false}>
                <ModalHeader>
                    {` ${selectedRow?.index || ''}  - ${selectedRow ? ' ' : ''} ${selectedRow?.variable || ''} - ${parametersBackup && parametersBackup[selectedRow?.connection_id] && parametersBackup[selectedRow?.connection_id].connection_name || ' '}`}

                    {selectedRow?.type === 'Visualization' && (
                        <Button
                            onClick={() => {
                                setCurrentTitleHelp('Ayuda de favoritos de visualización');
                                setCurrentMessageHelp(
                                    'Estos favoritos nos permiten añadir variables principales, incorporarlos en gráficas en tiempo real, registrar los datos para análisis futuros, y vincular favoritos de parametrización. Además, permite asignar colores específicos a cada favorito, facilitando la identificación visual y el seguimiento en la interfaz.'
                                );
                                toggleHelpModal();
                            }}
                            style={{
                                border: 'none',
                                background: 'none',
                                padding: 0,
                                outline: 'none',
                                boxShadow: 'none',
                            }}
                            className="btn-icon"
                        >
                            <i className="mdi mdi-help-circle text-primary fs-20"></i>
                        </Button>
                    )}
                </ModalHeader>
                {errorMessage && <div className="mt-4 alert alert-danger">{errorMessage}</div>}


                <Formik
                    initialValues={{
                        name: ensureNotNull(selectedRow?.name),
                        min_scale: ensureNotNull(selectedRow?.min_scale),
                        max_scale: ensureNotNull(selectedRow?.max_scale),
                        unit: (() => {
                            const unitValue = unitOptions.find(option => option.value == selectedRow?.unit)?.value || ensureNotNull(selectedRow?.unit);
                            return unitValue;
                        })(),
                        principal: selectedRow?.principal || false,
                        in_graph: selectedRow?.in_graph || false,
                        register: selectedRow?.register || false,
                        decimal_count: selectedRow?.decimal_count || 0,
                        color: ensureNotNull(selectedRow?.color),
                    }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, setFieldValue, values }) => {
                        const parameter = parametersBackup[Number(id)].parameters.find((param: any) => Number(param.parameter_id) === Number(selectedRow?.parameter_id));
                        const isUnitPercent = parameter?.unit === '%' && parameter?.variable.startsWith("Entrada");

                        return (
                            <Form>
                                <ModalBody>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Nombre personalizado</label>
                                        <Field
                                            type="text"
                                            className="form-control rounded-pill"
                                            id="name"
                                            name="name"
                                        />
                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                    </div>
                                    {isUnitPercent && selectedRow.type != 'Parametrization' && (
                                        <>
                                            <div className="mb-3">
                                                <label htmlFor="min_scale" className="form-label">Escala mínima</label>
                                                <Field
                                                    type="number"
                                                    className="form-control rounded-pill"
                                                    id="min_scale"
                                                    name="min_scale"
                                                    disabled={!!analogicResponse} // Deshabilitar si se ha detectado uso analógico
                                                />
                                                <ErrorMessage name="min_scale" component="div" className="text-danger" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="max_scale" className="form-label">Escala máxima</label>
                                                <Field
                                                    type="number"
                                                    className="form-control rounded-pill"
                                                    id="max_scale"
                                                    name="max_scale"
                                                    disabled={!!analogicResponse} // Deshabilitar si se ha detectado uso analógico
                                                />
                                                <ErrorMessage name="max_scale" component="div" className="text-danger" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="decimal_count" className="form-label">Decimales</label>
                                                <Field
                                                    type="number"
                                                    className="form-control rounded-pill"
                                                    id="decimal_count"
                                                    name="decimal_count"
                                                    disabled={!!analogicResponse}
                                                />
                                                <ErrorMessage name="decimal_count" component="div" className="text-danger" />
                                            </div>

                                            <div className="mb-3">
                                                <Label for="unit">Unidad</Label>
                                                {errorMessage ? (
                                                    <Input
                                                        type="select"
                                                        name="unit"
                                                        id="unit"
                                                        className='rounded-pill'
                                                        value={selectedRow.unit}
                                                        onChange={handleUnitChange}
                                                    >
                                                        {unitOptions.map((option, index) => (
                                                            <option key={index} value={option.value}>{option.label}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <Input
                                                        type="text"
                                                        className='rounded-pill'
                                                        name="unit"
                                                        id="unit"
                                                    />
                                                )}
                                                <ErrorMessage name="unit" component="div" className="text-danger" />
                                            </div>
                                        </>
                                    )}
                                    {selectedRow.type != 'Parametrization' && (
                                        <>


                                            <div className='d-flex justify-content-between'>
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="form-check form-switch form-switch-lg">
                                                        <Field
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="principal"
                                                            name="principal"
                                                        />
                                                    </div>
                                                    <span>Variable principal</span>
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="form-check form-switch form-switch-lg">
                                                        <Field
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="in_graph"
                                                            name="in_graph"
                                                        />
                                                    </div>
                                                    <span>En gráfica</span>
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="form-check form-switch form-switch-lg">
                                                        <Field
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="register"
                                                            name="register"
                                                        />
                                                    </div>
                                                    <span>Registro</span>
                                                </div>
                                            </div>
                                        </>
                                    )}


                                    <div className="mb-3 d-flex justify-content-center">
                                        <Button
                                            className="rounded-pill"
                                            color={values.principal ? "primary" : "secondary"}
                                            onClick={() => setParametersModalOpen(true)}
                                            disabled={!values.principal}
                                        >
                                            parámetros vinculados
                                        </Button>
                                    </div>


                                    {selectedRow.type != 'Parametrization' && (
                                        <div className="mb-3">
                                            <label className="form-label">Color</label>
                                            <div className="d-flex flex-wrap">
                                                {colors.map((color) => (
                                                    <div
                                                        key={color.hex}
                                                        onClick={() => setFieldValue('color', color.hex)}
                                                        style={{
                                                            backgroundColor: color.hex,
                                                            width: '30px',
                                                            height: '30px',
                                                            margin: '5px',
                                                            borderRadius: '50%',
                                                            cursor: 'pointer',
                                                            border: values.color === color.hex ? '3px solid black' : '1px solid gray',
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                            <ErrorMessage name="color" component="div" className="text-danger" />
                                        </div>
                                    )}
                                </ModalBody>
                                <ModalFooter className='d-flex justify-content-center'>
                                    <Button color="secondary" className='rounded-pill' onClick={() => setActionModalOpen(false)}>Cancelar</Button>
                                    <Button color="primary" className='rounded-pill' type="submit">Guardar</Button>

                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
            <ModalLinkedParameters
                isOpen={parametersModalOpen}
                toggle={() => setParametersModalOpen(!parametersModalOpen)}
                idSelected={selectedRow?.id || 0}
                selectedRow={selectedRow}
            />

            <BreadCrumb
                pageTitle={installation && installation.installation_name}
                pageTitleUrl={`/dashboard/instalacion/${installation && installation.id}`}
                subtitle={zone && zone.zone_name}
                subtitleUrl={`/dashboard/zona/${zone && zone.id}`}
                subsubtitle={parametersBackup && parametersBackup[connectionID] ? parametersBackup[connectionID].connection_name : ''}
                subsubtitleUrl={`/dispositivo/${connectionID}`}
                subsubsubtitle='Favoritos'
            />

            <div className="page-content">
                <Container fluid>
                    <Col lg={12} className="order-xxl-0 order-first">
                        <GeneralData registers={sidebarData} />
                    </Col>
                    <Row className="d-flex h-100">
                        <ModalHelp
                            isOpen={isHelpModalOpen}
                            toggle={toggleHelpModal}
                            title={currentTitleHelp}
                            body={currentMessageHelp}
                            buttonText="Cerrar"
                            imageSrc={favoriteVisualizationHelp}
                        />
                        <Col md={6} className="d-flex flex-column p-0" >
                            <Card className=" px-3 pt-3 flex-grow-1 m-2 rounded shadow-sm">
                                <div className=" h-100">
                                    <div className="d-flex align-items-center mb-3">
                                        <Button
                                            onClick={() => {
                                                setCurrentTitleHelp('Ayuda de Favoritos de visualización');
                                                setCurrentMessageHelp(
                                                    'Estos favoritos nos permiten añadir variables principales, incorporarlos en gráficas en tiempo real, registrar los datos para análisis futuros, y vincular favoritos de parametrización. Además, permite asignar colores específicos a cada favorito, facilitando la identificación visual y el seguimiento en la interfaz.');
                                                toggleHelpModal();
                                            }}
                                            style={{
                                                border: 'none',
                                                background: 'none',
                                                padding: 0,
                                                outline: 'none',
                                                boxShadow: 'none'
                                            }}
                                            className="btn-icon"
                                        >
                                            <i className="mdi mdi-help-circle text-primary fs-20"></i>
                                        </Button>
                                        <h5 className="flex-grow-1 fs-16 mb-0">
                                            {`Visualización - ${parametersBackup && parametersBackup[connectionID] ? parametersBackup[connectionID].connection_name : ''}`}
                                        </h5>

                                    </div>
                                    {!favorites[connectionID] ? (
                                        <div className="text-center vh-100 d-flex justify-content-center align-items-center">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <TableContainerFavorites
                                            columns={allColumnsVisualization || []}
                                            data={visualizationData || []}
                                            isPagination={false}
                                            isGlobalFilter={true}
                                            isBordered={false}
                                            customPageSize={200}
                                            className="custom-header-css table align-middle table-nowrap"
                                            tableClass="table-centered align-middle table-nowrap mb-0"
                                            theadClass="text-muted table-light p-0"
                                            thClass={"p-1"}
                                            SearchPlaceholder='Busca un parámetro de visualización...'
                                            type={'Visualization'}
                                            newOrder={newOrder}
                                            id={Number(id)}
                                        />
                                    )}
                                </div>
                            </Card>
                        </Col>
                        <Col md={6} className="d-flex flex-column p-0">
                            <Card className="m-2 px-3 pt-3 flex-grow-1 rounded shadow-sm">
                                <div className="h-100">
                                    <div className="d-flex align-items-center mb-3">
                                        <Button
                                            onClick={() => {
                                                setCurrentTitleHelp('Ayuda de Favoritos de parametrización');
                                                setCurrentMessageHelp(
                                                    'Estos favoritos de parametrización permiten a los usuarios cambiar los valores de los parámetros directamente.');
                                                toggleHelpModal();
                                            }}
                                            style={{
                                                border: 'none',
                                                background: 'none',
                                                padding: 0,
                                                outline: 'none',
                                                boxShadow: 'none'
                                            }}
                                            className="btn-icon"
                                        >
                                            <i className="mdi mdi-help-circle text-primary fs-20"></i>
                                        </Button>
                                        <h5 className="flex-grow-1 fs-16 mb-0">
                                            {`Parametrización - ${parametersBackup && parametersBackup[connectionID] ? parametersBackup[connectionID].connection_name : ''}`}
                                        </h5>

                                    </div>
                                    {!favorites[connectionID] ? (
                                        <div className="text-center vh-100 d-flex justify-content-center align-items-center">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <TableContainerFavorites
                                            columns={allColumnsParametrization || []}
                                            data={parametrizationData || []}
                                            isPagination={false}
                                            isGlobalFilter={true}
                                            isBordered={false}
                                            customPageSize={200}
                                            className="custom-header-css table align-middle table-nowrap"
                                            tableClass="table-centered align-middle table-nowrap mb-0"
                                            theadClass="text-muted table-light"
                                            thClass={"p-1"}
                                            type={'Parametrization'}
                                            SearchPlaceholder='Busca un parámetro de parametrización...'
                                            newOrder={newOrder}
                                            id={Number(id)}
                                        />
                                    )}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Favorites;
