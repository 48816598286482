import React, { useEffect, useState, useContext } from 'react';
import { Button, Card, CardBody, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Devices from './Devices';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from 'context/SocketProvider';
import NoInstallations from 'pages/Initial/NoInstallations';
import NoZones from 'pages/Initial/NoZones';
import { getDashboardInfo } from 'slices/favorites/thunk';
import { setDashBoardInfo, updateDashboardValues } from 'slices/favorites/reducer';
import { getParameterValuesByConnection } from 'slices/parameterValues/thunk';
import GeneralData from './GeneralData';
import DashboardActions from './DashboardActions';
import FavoritesDragDropList from './FavoritesDragDropList';

import ModalConfirm from 'Components/Common/Modals/ModalConfirm';

import { has } from 'lodash';
import ChartJSBasicRealtime from './ChartJSBasicRealtime';
import rolesIds from 'constants/roles';
import Empty from 'pages/Initial/Empty';
import { dashboardChat } from 'common/data';
import { toast } from 'react-toastify';
import { updateCalculatedParameters } from 'slices/calculatedParameters/reducer';
import { resetActiveFaults } from 'slices/faultHistory/reducer';
import { resetActiveAlarms } from 'slices/alarmHistory/reducer';

const Dashboard: React.FC = () => {
    document.title = "DashBoard | Ecentroserver";
    const dispatch: any = useDispatch();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const socket = useContext(SocketContext);
    const [hasEmitted, setHasEmitted] = useState(false);
    const [connections, setConnections] = useState<any[]>([]);

    const selectFavoritesState = (state: any) => state.Favorites;
    const selectInstallationsState = (state: any) => state.Installations;
    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const selectCalculatedParametersState = (state: any) => state.CalculatedParameters;
    const selectStatusState = (state: any) => state.Status;

    const profiledropdownData = createSelector(
        (state: any) => state.Profile.user,
        (user) => user
    );

    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state) => state
    );

    const FavoritesProperties = createSelector(
        selectFavoritesState,
        (state) => state
    );

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state) => state
    );

    const parameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state) => state
    );

    const calculatedParametersProperties = createSelector(
        selectCalculatedParametersState,
        (state) => state
    );

    const statusProperties = createSelector(
        selectStatusState,
        (state) => state
    );

    const { installations } = useSelector(installationDataProperties);
    const { parameterValues } = useSelector(parameterValuesProperties);
    const { parametersBackup } = useSelector(parametersBackupProperties);
    const { favorites, graph_favorites } = useSelector(FavoritesProperties);
    const calculatedParameters = useSelector((state: any) => {
        const params = state.CalculatedParameters.calculatedParameters[Number(id)];
        return Array.isArray(params) ? params : [];
    });

    const dashBoardInfo = useSelector((state: any) => {
        // Obtiene la información del dashboard específica para el zoneId actual
        return state.Favorites.dashBoardInfo[Number(id)] || {};
    });



    const user = useSelector(profiledropdownData);
    const { status } = useSelector(statusProperties);
    const location = useLocation();


    useEffect(() => {
        if (id) {
            dispatch(getDashboardInfo(Number(id)));
        }
    }, [dispatch, id, installations]);

    useEffect(() => {
        if (installations && Array.isArray(installations)) {
            const matchedZones = installations.flatMap((installation: any) =>
                installation.zones.filter((zone: any) => zone.id == Number(id))
            );

            if (matchedZones && Array.isArray(matchedZones) && matchedZones.length > 0) {
                setConnections(matchedZones[0].connections);
            }
        }
    }, [installations, id]);

    useEffect(() => {
        if (connections && Array.isArray(connections) && connections.length > 0) {
            setHasEmitted(false);
        }
    }, [dashBoardInfo.status]);

    useEffect(() => {
        if (installations && Array.isArray(installations)) {
            const matchedInstallation = installations.find((installation: any) =>
                installation.zones.some((zone: any) => zone.id === Number(id))
            );

            if (!matchedInstallation) {
                const activeInstallation = installations.find((installation: any) => installation.is_active);
                if (activeInstallation) {
                    navigate(`/dashboard/instalacion/${activeInstallation.id}`);
                }
            }
        }
    }, [installations, id, navigate]);


    /*     useEffect(() => {
            const handleModbusValues = (data: any) => {
    
                const payload = {
                    data: data,
                    parametersBackup: parametersBackup,
                    zoneId: Number(id)
                };
                if (data && data.values && parametersBackup.length > 0) {
                    console.log("recibiendo....")
                    dispatch(updateDashboardValues(payload));
    
    
                    if (parametersBackup.length > 0) {
                        dispatch(updateCalculatedParameters({ parametersBackup: parametersBackup, favorites: favorites, data: data }));
                    }
    
                }
    
            };
    
            if (socket) {
                socket.on('send_modbus_values_multiples_ips', handleModbusValues);
            }
    
            return () => {
                if (socket) {
                    socket.off('send_modbus_values_multiples_ips', handleModbusValues);
                }
            };
    
        }, [socket, dashBoardInfo, dispatch, hasEmitted, parametersBackup, favorites]); */

    useEffect(() => {
        if (socket) {
            socket.on('resetConfirmation', (data: any) => {
                toast.success(`Reset realizado para IPs`);
                dispatch(resetActiveFaults())
                dispatch(resetActiveAlarms())
            });

            socket.on('resetError', (data: any) => {
                toast.error(`Error al resetear las siguientes IPs: ${data.ips.join(', ')}`);
            });
        }

        return () => {
            if (socket) {
                socket.off('resetConfirmation');
                socket.off('resetError');
            }
        };
    }, [socket]);

    const handleConfirm = () => {
        setModal(false);
    };

    const handleCancel = () => {
        setModal(false);
    };

    const [modal, setModal] = useState(false);
    const [selectedFavorite, setSelectedFavorite] = useState<{ color?: string, isChecked?: boolean, name?: string } | null>({ color: '', isChecked: false, name: '' });
    const [isEnabledOrDisabledModalOpen, setIsEnabledOrDisabledModalOpen] = useState(false);
    const [resetDevicesModalOpen, setResetDevicesModalOpen] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [addToChartModalOpen, setAddToChartModalOpen] = useState(false);

    const handleConfirmAddToChart = () => {
        setAddToChartModalOpen(false);
    };

    const handleCancelAddToChart = () => {
        setAddToChartModalOpen(false);
    };

    const handleConfirmEnabledOrDisabled = () => {
        setIsEnabledOrDisabledModalOpen(false);
    };

    const handleCancelEnabledOrDisabled = () => {
        setIsEnabledOrDisabledModalOpen(false);
    };

    const handleConfirmResetDevices = (data: any) => {
        const registers = dashBoardInfo.ip_registers;

        let keysArray = [];

        if (Array.isArray(registers)) {
            keysArray = registers.reduce((acc, register) => {
                return acc.concat(Object.keys(register));
            }, []);
        } else if (typeof registers === 'object' && registers !== null) {
            keysArray = Object.keys(registers);
        } else {
            console.error("registers no es ni un array ni un objeto:", registers);
        }

        if (socket) {
            socket.emit('resetDevices', keysArray);
        }

        setResetDevicesModalOpen(false);
    };

    const handleCancelResetDevices = () => {
        setResetDevicesModalOpen(false);
    };

    const toggleModal = () => setModal(!modal);

    const confirmActionEnabledOrDisabled = () => {
        setIsEnabledOrDisabledModalOpen(true);
    };

    const confirmActionResetDevices = () => {
        setResetDevicesModalOpen(true);
    };

    const confirmActionAddToChart = () => {
        setAddToChartModalOpen(true);
    };

    const handleChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedFavorite((current) => current ? { ...current, color: value } : null);
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        setSelectedFavorite((current) => current ? { ...current, isChecked: value } : null);
    };

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFavorite((current) => current ? { ...current, name: event.target.value } : null);
    };


    return (
        Array.isArray(installations) && installations.filter((installation: any) => installation.is_active).length === 0 && user.role_id != rolesIds.operator &&
            user.role_id != rolesIds.technician
            ? (

                <NoInstallations />
            ) : Array.isArray(installations) && installations.every((installation: any) => installation.zones.length === 0 || installation.zones.every((zone: any) => !zone.is_active)) && user.role_id != rolesIds.operator &&
                user.role_id != rolesIds.technician ? (
                <NoZones />

            ) : Array.isArray(installations) && installations.every((installation: any) => installation.zones.length === 0 && installation.zones.every((zone: any) => !zone.is_active)) && (user.role_id == rolesIds.operator || user.role_id == rolesIds.technician) ?
                (
                    <Empty />
                ) :

                (
                    <React.Fragment>
                        <BreadCrumb
                            pageTitle={Array.isArray(installations) && installations
                                .find((installation: any) => installation.zones
                                    .some((zone: any) => zone.id === Number(id) && zone.is_active))?.installation_name}

                            pageTitleUrl={Array.isArray(installations) && installations
                                .find((installation: any) => installation.zones
                                    .some((zone: any) => zone.id === Number(id) && zone.is_active))
                                ? `/dashboard/instalacion/${installations.find((installation: any) =>
                                    installation.zones.some((zone: any) => zone.id === Number(id) && zone.is_active))?.id}`
                                : ''}

                            subtitle={Array.isArray(installations) && installations
                                .flatMap((installation: any) => installation.zones)
                                .find((zone: any) => zone.id == Number(id) && zone.is_active)?.zone_name}

                            subsubtitle={"Dashboard"}
                        />
                        <div className="page-content">
                            <Container fluid>

                                <Row>
                                    <Col lg={12} className="order-xxl-0 order-first">
                                        <GeneralData registers={dashBoardInfo.ip_registers} />
                                    </Col>
                                </Row>
                                <div className='d-md-flex'>
                                    <Col lg={5} className="custom-col mb-3 mb-md-0">
                                        <FavoritesDragDropList mainVariables={dashBoardInfo.main_variables || []} />
                                    </Col>
                                    <Col lg={5} className='custom-col-graph mb-md-0'>
                                        <Card className="flex-grow-1 m-0" style={{ height: '100%' }}>
                                            <CardBody className="p-0" style={{ height: '100%' }}>
                                                <div style={{ height: '100%' }}>
                                                    <ChartJSBasicRealtime calculatedParameters={calculatedParameters} inGraph={dashBoardInfo.graph_favorites || []} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </div>
                                <Row>
                                    <Col lg={12}>
                                        <Devices favorites={dashBoardInfo.favorites || []} id={id} status={dashBoardInfo.status} />
                                    </Col>
                                </Row>
                                <Modal isOpen={modal} toggle={toggleModal}>
                                    <ModalHeader toggle={toggleModal}>Detalle del Favorito</ModalHeader>
                                    <ModalBody>
                                        <div>
                                            <label>Color de fondo</label>
                                            <Input id="colorSelect" type="select" value={selectedFavorite?.color} onChange={handleChangeColor}>
                                                <option value="red">Rojo</option>
                                                <option value="blue">Azul</option>
                                                <option value="green">Verde</option>
                                            </Input>
                                        </div>
                                        <div>
                                            <label>Modificar Nombre</label>
                                            <Input id="nameInput" type="text" value={selectedFavorite?.name} onChange={handleChangeName} />
                                        </div>
                                        <div className='mt-2'>
                                            <label style={{ marginRight: '8px' }}>Añadir a la gráfica</label>
                                            <Input id="checkboxGraph" onClick={confirmActionAddToChart} type="checkbox" checked={selectedFavorite?.isChecked} onChange={handleChangeCheckbox} />
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <Button color="light" style={{ marginRight: '8px' }} onClick={handleCancel}>Cancelar</Button>
                                            <Button color="success" onClick={handleConfirm}>Confirmar</Button>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <ModalConfirm
                                    isOpen={isEnabledOrDisabledModalOpen}
                                    toggle={() => setIsEnabledOrDisabledModalOpen(!isEnabledOrDisabledModalOpen)}
                                    title="Habilitar planta o deshabilitar planta"
                                    message="La planta está actualmente habilitada. Si confirma esta acción la deshabilitará. ¿Está usted seguro?."
                                    onConfirm={handleConfirmEnabledOrDisabled}
                                    onCancel={handleCancelEnabledOrDisabled}
                                    iconName='ri-information-line display-5 text-warning'
                                />

                                <ModalConfirm
                                    isOpen={resetDevicesModalOpen}
                                    toggle={() => setResetDevicesModalOpen(!resetDevicesModalOpen)}
                                    title="Resetear dispositivos"
                                    message="¿Está seguro que quiere resetear todos los dispositivos?"
                                    onConfirm={handleConfirmResetDevices}
                                    onCancel={handleCancelResetDevices}
                                    iconName='ri-information-line display-5 text-warning'
                                    idSelected={dashBoardInfo}
                                />

                                <ModalConfirm
                                    isOpen={addToChartModalOpen}
                                    toggle={() => setAddToChartModalOpen(!addToChartModalOpen)}
                                    title="Añadir a la gráfica"
                                    message="¿Está seguro de que quiere añadir a la gráfica?"
                                    onConfirm={handleConfirmAddToChart}
                                    onCancel={handleCancelAddToChart}
                                    iconName='ri-information-line display-5 text-warning'
                                />
                            </Container>
                        </div>
                    </React.Fragment>
                )
    );
};

export default Dashboard;
