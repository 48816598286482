import React, { useContext, useEffect, useState } from 'react';
import { Popover, PopoverBody, Form, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { updateParameterBackup } from 'slices/parametersBackup/thunk';
import { getParameterValuesByConnection } from 'slices/parameterValues/thunk';
import { getLinkedFavorites } from 'slices/favorites/thunk';
import ParameterRow from 'pages/Parameters/ParameterRow';
import { toast } from 'react-toastify';
import { SocketContext } from 'context/SocketProvider';
import { setSelectedFavoriteId } from 'slices/favorites/reducer';

interface Parameter {
    id: number;
    value: any;
    index: string;
    favorite_name?: string;
    unit?: string;
    parameter_type: string;
}

interface Favorite {
    parameter_id: number;
    name: string;
    position: number; // Asegúrate de tener esta propiedad en tus datos
}

interface LinkedParamsTooltipProps {
    target: string;
    isOpen: boolean;
    toggle: () => void;
    connection_id: number;
    favorite_id: number;
}

const LinkedParamsTooltip: React.FC<LinkedParamsTooltipProps> = ({ target, isOpen, toggle, connection_id, favorite_id }) => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [parameters, setParameters] = useState<Parameter[]>([]);
    const [placement, setPlacement] = useState<"bottom" | "right">("right");
    const dispatch: any = useDispatch();
    const selectFavoritesState = (state: any) => state.Favorites;
    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const socket = useContext(SocketContext);

    const FavoritesProperties = createSelector(
        selectFavoritesState,
        (state) => state
    );

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state) => state
    );

    const parameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state) => state
    );

    const { parameterValues } = useSelector(parameterValuesProperties);
    const { parametersBackup } = useSelector(parametersBackupProperties);
    const { linkedFavorites, favorites } = useSelector(FavoritesProperties);

    useEffect(() => {
        if (window.innerWidth < 992) {
            setPlacement("bottom");
        } else {
            setPlacement("right");
        }
    }, [isOpen]);

    useEffect(() => {
        if (socket) {
            socket.on('changeValueResponse', (response: any) => {
                if (response.status === 'success') {
                    toast.success("Se ha modificado el parámetro correctamente.");
                    const parameter = parametersBackup[connection_id].parameters.find((parameter: any) => parameter.parameter_id == response.id.toString());
                    const newParametersBackup = parametersBackup[connection_id].parameters.map((parameter: any) => {
                        if (parameter.parameter_id == response.id.toString()) {
                            return { ...parameter, value: response.value };
                        }
                        return parameter;
                    });

                    const parameterInfo = {
                        connection_id: connection_id,
                        parameter_id: response.id,
                        value: response.value,
                        parameterValues: parameterValues[connection_id][parameter.id],
                        parametersBackup: newParametersBackup
                    };

                    dispatch(updateParameterBackup(parameterInfo));
                } else {
                    toast.error("Hubo un error a la hora de modificar el parámetro");
                }
            });
        }

        return () => {
            if (socket) {
                socket.off('changeValueResponse');
            }
        };
    }, [socket, parametersBackup, parameterValues]);

    /*   useEffect(() => {
          const fetchData = async () => {
              if (!parameterValues || !parameterValues[Number(connection_id)]) {
                  await dispatch(getParameterValuesByConnection(Number(connection_id)));
              }
  
              if ((!parametersBackup || !parametersBackup[Number(connection_id)]) && parameterValues[Number(connection_id)]) {
                  await dispatch(getParametersBackup({ connectionId: Number(connection_id), parameterValues: parameterValues[Number(connection_id)] }));
              }
  
              if (parametersBackup[Number(connection_id)]) {
                  setLoading(false);
              }
          };
  
          fetchData();
      }, [dispatch, connection_id, parameterValues, parametersBackup, isOpen]); */

    useEffect(() => {
        if (!loading) {
            dispatch(getLinkedFavorites(favorite_id));
        }
    }, [dispatch, favorite_id, loading]);

    useEffect(() => {
        if (parametersBackup[Number(connection_id)] && parametersBackup[Number(connection_id)].parameters && Array.isArray(linkedFavorites)) {
            const filteredParams = parametersBackup[Number(connection_id)].parameters
                .filter((pb: Parameter) =>
                    linkedFavorites.some((lf: Favorite) => Number(lf.parameter_id) === Number(pb.id))
                )
                .map((pb: Parameter) => {
                    const favorite = linkedFavorites.find((lf: Favorite) => Number(lf.parameter_id) === Number(pb.id));
                    return {
                        ...pb,
                        favorite_name: favorite ? favorite.name : ''
                    };
                })
                .sort((a: Parameter, b: Parameter) => {
                    const favoriteA = linkedFavorites.find((lf: Favorite) => Number(lf.parameter_id) === Number(a.id));
                    const favoriteB = linkedFavorites.find((lf: Favorite) => Number(lf.parameter_id) === Number(b.id));
                    return (favoriteA?.position || 0) - (favoriteB?.position || 0);
                });
            setParameters(filteredParams);
        }
    }, [parametersBackup, linkedFavorites, connection_id]);

    // Reset states when popover closes
    useEffect(() => {
        if (!isOpen) {
            setLoading(true);
            setParameters([]);
        }
    }, [isOpen]);

    const updateData = (index: string, columnId: string, value: any) => {
        setParameters(prev => {
            const newData = [...prev];
            const paramIndex = newData.findIndex(param => param.index === index);
            if (paramIndex !== -1) {
                newData[paramIndex] = {
                    ...newData[paramIndex],
                    value: value
                };
            }
            return newData;
        });
    };

    const handleConfigureClick = () => {
        dispatch(setSelectedFavoriteId(favorite_id));
    };

    const toggleModal = () => setModal(!modal);

    return (
        <Popover
            placement={placement}
            isOpen={isOpen}
            target={target}
            toggle={toggle}
            className="custom-popover"
            style={{ zIndex: 1000 }}
        >
            <PopoverBody>
                {loading ? (
                    <div className="text-center">
                        <Spinner />
                    </div>
                ) : (
                    <Form>
                        <div className="row" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            {parameters.map((paramBackup, index) => {
                                const favorite = linkedFavorites.find((lf: any) => Number(lf.parameter_id) === Number(paramBackup.id));
                                const favoriteName = favorite ? `${paramBackup.index}-${favorite.name}` : `Sin nombre (${paramBackup.index})`;

                                return (
                                    <FormGroup style={{
                                        backgroundColor: favorite && favorite.color ? favorite.color : 'white',
                                        height: paramBackup.parameter_type !== 'ReadWrite' ? 'auto' : 'auto',
                                        zIndex: 9999
                                    }} className="col-12 custom-bg rounded shadow-lg text-center" key={index}>
                                        <Label for={`input${index + 1}`}>{favoriteName}</Label>
                                        <div
                                            style={
                                                paramBackup.parameter_type === 'ReadWrite' || paramBackup.parameter_type === 'Visualization'
                                                    ? { paddingRight: '90px', paddingLeft: '90px', zIndex: 9999 }
                                                    : paramBackup.parameter_type === 'Select'
                                                        ? { display: 'flex', justifyContent: 'center' }
                                                        : {}
                                            }
                                            className={paramBackup.unit ? 'd-flex pb-1' : 'pb-2'}
                                        >
                                            <ParameterRow
                                                id={connection_id}
                                                cell={null}
                                                value={paramBackup.value}
                                                parameter={paramBackup} // Pasar el parámetro completo
                                                updateData={updateData}
                                            />
                                            {paramBackup.unit && <span>{paramBackup.unit}</span>}
                                        </div>
                                    </FormGroup>
                                );
                            })}
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <Button onClick={toggle} className={'rounded-pill'} color="primary" size="sm" style={{ fontSize: '12px' }}>Cerrar</Button>
                            <Link
                                to={`/favoritos/${connection_id}`}
                                onClick={handleConfigureClick}
                                className="btn btn-link text-center rounded-pill"
                                style={{ fontSize: '12px' }}>
                                <i className='ri-settings-4-fill'></i>
                                Configurar
                            </Link>
                        </div>
                    </Form>
                )}
            </PopoverBody>
            <Modal isOpen={modal} toggle={toggleModal} className="d-flex justify-content-center align-items-center">
                <ModalHeader toggle={toggleModal}>Configuración</ModalHeader>
                <ModalBody>
                    Contenido de configuración
                </ModalBody>
            </Modal>
        </Popover>
    );
};

export default LinkedParamsTooltip;
